import React from "react";
import Helmet from "react-helmet";
import environment from "projects/common/shared/config/environment";

interface OwnProps {}

type Props = OwnProps;

const Analytics: React.FC<Props> = () => {
  if (!environment.YANDEX_METRIC_ENABLE || !environment.YANDEX_METRIC_NUMBER) {
    return null;
  }

  const number = environment.YANDEX_METRIC_NUMBER;

  return (
    <Helmet>
      <script type="text/javascript">
        {`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(${number}, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });
        `}
      </script>
      <noscript>
        {`
        <div><img src="https://mc.yandex.ru/watch/${number}" style="position:absolute; left:-9999px;" alt="" /></div>
        `}
      </noscript>
    </Helmet>
  );
};

export default Analytics;
