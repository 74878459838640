import React, { PropsWithChildren } from "react";
import DropdownAnt, { DropDownProps } from "antd/es/dropdown";
import "./Dropdown.scss";
import clsx from "clsx";

interface OwnProps extends DropDownProps {
  onClick?: (event: any) => void;
}

type Props = OwnProps;

const Dropdown = (props: PropsWithChildren<Props>) => {
  const { className, overlayClassName, ...params } = props;

  const handleClick = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <DropdownAnt
      onClick={handleClick}
      arrow={true}
      {...props}
      className={clsx(["e2e-dropdown", className])}
      overlayClassName={clsx(["e2e-overlay", className])}
    />
  );
};

export default Dropdown;
