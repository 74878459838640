import React, { PropsWithChildren } from "react";
import Fallback from "projects/common/shared/ui/fallback/Fallback";

const AuditBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'common-audit' */
      /* webpackPrefetch: 10 */
      "./AuditRouter"
    )
);

const AuditModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <AuditBundle />
  </React.Suspense>
);

export default AuditModule;
