import { ScenarioItemType } from "projects/common/types/enums/campaign";
import { ReportForm } from "projects/common/types/interfaces/report";
import { MessageState, ReportType, SourceTraffics } from "projects/common/types/enums/report";
import { ReportState } from "projects/common/types/models/reports";
import { projectCheckSourceTraffic } from "projects/common/entities/app/project";

export const REPORT_STATUSES: any = {
  [ReportState.NEW]: { title: "Создается", view: false, finished: false },
  [ReportState.DRAFT]: { title: "Черновик", view: true, finished: false },
  [ReportState.CREATING]: { title: "Готовится", view: true, finished: false },
  [ReportState.COMPLETE]: { title: "Готов", view: true, finished: true },
  [ReportState.CANCEL]: { title: "Отменен", view: true, finished: true },
  [ReportState.FAILED]: { title: "Не выполнен", view: true, finished: true },
  [ReportState.DELETED]: { title: "Удалена", view: false, finished: true },
  [ReportState.ARCHIVED]: { title: "В архиве", view: true, finished: true }
};

export const REPORT_STATUS_FILTER = [
  { title: "Черновик", id: ReportState.DRAFT },
  { title: "Готовится", id: ReportState.CREATING },
  { title: "Готов", id: ReportState.COMPLETE },
  { title: "Не выполнен", id: ReportState.FAILED },
  { title: "В архиве", id: ReportState.ARCHIVED }
];

// Все отчеты которые может показывать данный проект
export const REPORT_TYPE = [
  {
    title: "Сводный",
    type: ReportType.SUMMARY
  },
  { title: "Детализированный", type: ReportType.TRAFFIC },
  {
    title: "По выбранным рассылкам",
    type: ReportType.CAMPAIGN
  },
  {
    title: "Финансовая справка",
    type: ReportType.FINANCIAL
  },
  {
    title: "Отчет о переходах по коротким ссылкам",
    type: ReportType.SHORT_URL
  }
];

export const REPORT_TYPES_DATE_TIME = [ReportType.TRAFFIC, ReportType.SUMMARY, ReportType.SHORT_URL];

export const REPORT_FORMS: ReportForm[] = [
  {
    id: "traffics",
    name: "Источник трафика",
    title: "Источник трафика"
  },
  {
    id: "campaigns",
    name: "Список рассылок",
    title: "Рассылки"
  },
  {
    id: "date",
    name: "Период для формирования отчета",
    title: "Период для формирования отчета"
  },
  {
    id: "channel",
    name: "Каналы",
    title: "Каналы"
  },
  {
    id: "statuses",
    name: "Фильтр по статусу",
    title: "Фильтр по статусу"
  },
  {
    id: "group",
    name: "Группировка",
    title: "Группировка"
  },
  {
    id: "filter",
    name: "Фильтры",
    title: "Фильтры"
  }
];

export const SOURCE_TRAFFICS: { [key in SourceTraffics]: any } = {
  [SourceTraffics.HTTP]: "HTTP",
  [SourceTraffics.SMPP]: "SMPP",
  [SourceTraffics.WEB]: "WEB",
  [SourceTraffics.EMAIL]: "EMAIL",
  [SourceTraffics.WEB_SM]: "WEB_SM"
};

export const SOURCE_TRAFFIC_ARRAY: SourceTraffics[] = Object.entries(SOURCE_TRAFFICS)
  .map(([key, value]) => key as SourceTraffics)
  .filter((key) => projectCheckSourceTraffic(key));

export const REPORT_SCENARIO_TYPE: { [key in ScenarioItemType]?: string } = {
  [ScenarioItemType.VKOK]: "Vk/Ok",
  [ScenarioItemType.VIBER]: "Viber",
  [ScenarioItemType.SMS]: "SMS",
  [ScenarioItemType.WHATSAPP]: "WhatsApp",
  [ScenarioItemType.PUSH]: "PUSH"
};

export const MESSAGE_STATE: { [key in MessageState]: { title: string; view: boolean } } = {
  [MessageState.PENDING]: { title: "Ожидает отправки", view: true },
  [MessageState.SENT]: { title: "Отправлено", view: true },
  [MessageState.DELIVERED]: { title: "Доставлено", view: true },
  [MessageState.NOT_DELIVERED]: { title: "Не доставлено", view: true },
  [MessageState.FAILED]: { title: "Просрочено", view: false },
  [MessageState.SEEN]: { title: "Просмотрено", view: true },
  [MessageState.ACCEPTED]: { title: "сообщение принято платформой", view: false },
  [MessageState.REJECTED]: { title: "сообщение не может быть обработано", view: false },
  [MessageState.SCHEDULED]: { title: "отправка сообщения запланирована по расписанию", view: false },
  [MessageState.CANCELED]: { title: "отправка сообщения по расписанию отменена", view: false },
  [MessageState.EXPIRED]: { title: "статус сообщения не получен в течение TTL", view: false },
  [MessageState.UNKNOWN]: { title: "статус сообщения неизвестен", view: false }
};

export const MESSAGE_STATE_VIEWED: MessageState[] = Object.entries<{ title: string; view: boolean }>(MESSAGE_STATE)
  .filter(([key, value]) => value?.view)
  .map(([key, value]) => key as MessageState);

export const REPORT_ADDITIONAL_GROUP_KEY = "grouping_fields";

export const REPORT_GROUPS = [
  {
    id: "channel",
    name: "Канал"
  },
  {
    id: "operator",
    name: "Оператор"
  },
  {
    id: "traffic_type",
    name: "Тип трафика"
  },
  {
    id: "sender",
    name: "Имя отправителя"
  },
  {
    id: "department",
    name: "Департамент"
  },
  {
    id: "login",
    name: "Пользователь (логин)"
  },
  {
    id: "request_source",
    name: "Источник трафика"
  },
  {
    id: "country",
    name: "Страна"
  },
  {
    id: "campaign",
    name: "Рассылка"
  },
  {
    id: "date",
    name: "Дата"
  },
  {
    id: "state",
    name: "Статус"
  },
  {
    id: "pattern",
    name: "Шаблон"
  }
] as const;

export const REPORT_GROUP_ID = REPORT_GROUPS.map((group) => group.id);

export const REPORT_GROUP_DEFAULT_ID: typeof REPORT_GROUP_ID = ["channel", "operator", "traffic_type", "sender"];
