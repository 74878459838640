/**
 * User-model для управления пользователями/департаментами из личного кабинета
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PageRequestFilterValue } from "./pageRequestFilterValue";

/**
 * Параметры для запросов с пагинацией
 */
export interface PageRequest {
  /**
   * Количество эелемнтов на странице
   */
  size: number;
  /**
   * Номер страницы начиная с 0
   */
  page: number;
  /**
   * Сортировка с возможностью указать несколько полей. Данные сортируются в том порядке, в котором указаны.
   */
  sort?: { [key: string]: string };
  /**
   * Фильтр по полям
   */
  filter?: { [key: string]: PageRequestFilterValue };
}
export namespace PageRequest {
  export type SortEnum = "ASC" | "DESC";
  export const SortEnum = {
    Asc: "ASC" as SortEnum,
    Desc: "DESC" as SortEnum
  };
}
