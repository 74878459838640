import React, { PropsWithChildren } from "react";
import logo from "projects/gpbm/app/assets/logo.svg";
import logoMin from "projects/gpbm/app/assets/logo-min.svg";
import styles from "./Logo.module.scss";
import { NavLink } from "react-router-dom";

interface OwnProps {
  collapsed?: boolean;
  id?: string;
}

export type Props = OwnProps;

const Logo = (props: PropsWithChildren<Props>) => {
  const { id, collapsed = false } = props;

  return (
    <NavLink to="/" id={id}>
      <div className={styles.logo}>
        <img className={styles.img} src={collapsed ? logoMin : logo} alt="Logo" />
      </div>
    </NavLink>
  );
};

export default Logo;
