import { DefaultBodyType, ResponseResolver, RestContext, RestRequest } from "msw";
import { MOCK_API_DELAY } from "./config";

type Response = (...data: any) => ResponseResolver<RestRequest<any, any>, RestContext, DefaultBodyType | any>;

export const mwsSuccess: Response = (mock: any, ctxObj?: []) => (req, res, ctx) => {
  return res(...(ctxObj || []), ctx.json(mock), ctx.delay(MOCK_API_DELAY));
};

export const mwsBlob: Response = (fileUrl: string, fileName: string, ctxObj?: []) => async (req, res, ctx) => {
  const imageBuffer = await fetch(fileUrl).then((res) => res.arrayBuffer());

  return res(
    ctx.set("Content-Length", imageBuffer.byteLength.toString()),
    ctx.set("Content-Type", "application/csv"),
    ctx.set("Content-Disposition", `attachment; filename="${fileName}"`),
    ctx.body(imageBuffer),
    ...(ctxObj || [])
  );
};

export const mwsError: Response =
  (status: number = 500, error: any = { errorMessage: "errorMessage" }, ctxObj?: []) =>
  (req, res, ctx) => {
    return res(...(ctxObj || []), ctx.status(status), ctx.json(error));
  };
