/**
 * Message preset API
 * API для управления пресетами сообщений
 *
 * OpenAPI spec version: 1.0
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Статус пресета
 */

export enum PresetState {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  DELETED = "DELETED"
}
