import { TrafficType } from "projects/common/types/enums/message";
import { ScenarioItemType } from "projects/common/types/enums/campaign";
import { dateToServer } from "projects/common/shared/lib/date";
import { MessageLookupFiltersType } from "projects/common/types/interfaces/messageLookup";

const today = dateToServer(new Date());
export const SENT_AT_FROM_DEFAULT = `${today} 00:00:00`;
export const SENT_AT_TO_DEFAULT = `${today} 23:59:59`;
export const MESSAGE_LOOKUP_FILTERS_DEFAULT: MessageLookupFiltersType = {
  sentAtFrom: { value: SENT_AT_FROM_DEFAULT, rule: "STRICT" },
  sentAtTo: { value: SENT_AT_TO_DEFAULT, rule: "STRICT" }
};

export const TRAFFIC_TYPE: { [key in TrafficType]: { title: string; scenario: ScenarioItemType } } = {
  [TrafficType.INTERNATIONAL]: {
    title: "Международный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.MULTINAMING]: {
    title: "Мультиподпись",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.SERVICE]: {
    title: "Сервисный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.AUTHORIZATION]: {
    title: "Авторизационный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.GLOBAL]: {
    title: "Международное имя",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.TRANSACTIONAL]: {
    title: "Транзакционный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.ADVERTISING]: {
    title: "Рекламный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.TEMPLATED_PROMOTION]: {
    title: "Рекламный шаблонированный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.INFORMATIONAL]: {
    title: "Информационный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.IDENTIFICATIONAL]: {
    title: "Идентификационный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.IDENTIFICATIONAL]: {
    title: "Идентификационный",
    scenario: ScenarioItemType.SMS
  },
  [TrafficType.BUSINESS_INIT]: {
    title: "Инициировано компанией",
    scenario: ScenarioItemType.WHATSAPP
  },
  [TrafficType.USER_INIT]: {
    title: "Инициировано пользователем",
    scenario: ScenarioItemType.WHATSAPP
  },
  [TrafficType.TRANSACTION]: {
    title: "Транзакционный (Viber)",
    scenario: ScenarioItemType.VIBER
  },
  [TrafficType.PROMOTION]: {
    title: "Рекламный",
    scenario: ScenarioItemType.VIBER
  }
};
