export enum TrafficType {
  BUSINESS_INIT = "BUSINESS_INIT",
  INTERNATIONAL = "INTERNATIONAL",
  MULTINAMING = "MULTINAMING",
  INFORMATIONAL = "INFORMATIONAL",
  IDENTIFICATIONAL = "IDENTIFICATIONAL",
  SERVICE = "SERVICE",
  AUTHORIZATION = "AUTHORIZATION",
  GLOBAL = "GLOBAL",
  TRANSACTIONAL = "TRANSACTIONAL",
  TRANSACTION = "TRANSACTION",
  ADVERTISING = "ADVERTISING",
  PROMOTION = "PROMOTION",
  USER_INIT = "USER_INIT",
  TEMPLATED_PROMOTION = "TEMPLATED_PROMOTION"
}
