/**
 * Keycloak authentication API.
 * Аутентификация через keycloak
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GrantType = "password" | "refresh_token";

export const GrantType = {
  Password: "password" as GrantType,
  RefreshToken: "refresh_token" as GrantType
};
