import { ROUTE_PATH } from "projects/common/shared/config/routes";
import { Access } from "projects/common/types/interfaces/access";
import { RouteItem } from "projects/common/types/interfaces/route";

export const getMenu = (access: Access): RouteItem[] => {
  return [
    { title: "Рассылки", path: ROUTE_PATH.campaigns, id: "menu-campaigns", visible: !!access?.campaignVisible },
    {
      title: "Отправка сообщений",
      path: ROUTE_PATH.simpleMessage,
      id: "menu-simple-message",
      visible: !!access?.simpleMessageVisible
    },
    { title: "Отчёты", path: ROUTE_PATH.reports, id: "menu-reports", visible: !!access?.reportVisible },
    { title: "Списки абонентов", path: ROUTE_PATH.lists, id: "menu-lists", visible: !!access?.listVisible },
    {
      title: "Поиск сообщений",
      path: ROUTE_PATH.activityLog,
      id: "menu-message-lookup",
      visible: !!access?.messageLookupVisible
    },
    { title: "Имена", path: ROUTE_PATH.names, id: "menu-names", visible: !!access?.namesVisible, more: true },
    {
      title: "Шаблоны SMS",
      path: ROUTE_PATH.smsPatterns,
      id: "menu-sms-patterns",
      visible: !!access?.smsPatternsVisible,
      more: true
    },
    {
      title: "Проверка шаблона",
      path: ROUTE_PATH.smsPatternCheck,
      id: "menu-sms-patterns-check",
      visible: !!access?.smsPatternCheckVisible,
      more: true
    },
    { title: "Аудит действий", path: ROUTE_PATH.audit, id: "menu-audit", visible: !!access?.auditVisible, more: true },
    { title: "Пресеты", path: ROUTE_PATH.preset, id: "menu-preset", visible: !!access?.presetVisible, more: true },
    {
      title: "Управление",
      path: ROUTE_PATH.management,
      id: "menu-management",
      visible: !!access?.managementVisible,
      more: true
    }
  ];
};
