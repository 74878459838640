import { AccessProfile } from "../../types/models/user-access";

export const getAccessProfileMock = (data: Partial<AccessProfile> = {}): AccessProfile => {
  return {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Profile Name",
    ...data
  };
};

export default {
  accessProfiles: {
    data: [
      getAccessProfileMock({ id: "1" }),
      getAccessProfileMock({ id: "2" }),
      getAccessProfileMock({ id: "3" }),
      getAccessProfileMock({ id: "4" }),
      getAccessProfileMock({ id: "5" }),
      getAccessProfileMock({ id: "6" }),
      getAccessProfileMock({ id: "7" }),
      getAccessProfileMock({ id: "8" }),
      getAccessProfileMock({ id: "9" }),
      getAccessProfileMock({ id: "10" })
    ]
  }
};
