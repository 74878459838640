import React, { PropsWithChildren, useEffect, useState } from "react";
import { clsx } from "clsx";
import { Size } from "projects/common/types/interfaces/styles";
import { RouteItem } from "projects/common/types/interfaces/route";
import Navbar from "projects/common/shared/ui/navbar/Navbar";
import NavbarItem from "projects/common/shared/ui/navbar/NavbarItem";
import { getMenu } from "../menu";
import Trans from "projects/common/shared/ui/translation/Trans";
import { useAppSelector } from "projects/common/app/hooks/useStore";
import { selectAccess } from "projects/common/entities/user/model";
import DropdownButton from "../../../shared/ui/dropdown/DropdownButton";
import { getMenuItem } from "../../../shared/ui/menu/Menu";
import Icon from "../../../shared/ui/icon/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import type { ItemType } from "antd/es/menu/hooks/useItems";
import Span from "../../../shared/ui/typography/Span";
import { hasActiveRoute } from "../../../shared/lib/router";

interface OwnProps {
  size?: Size;
  border?: boolean;
}

type Props = OwnProps;

const MainMenu = (props: PropsWithChildren<Props>) => {
  const { size = "lg", border } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const access = useAppSelector(selectAccess);
  const [menu, setMenu] = useState<RouteItem[]>([]);
  const [moreMenu, setMoreMenu] = useState<ItemType[]>([]);
  const [visibleMore, setVisibleMore] = useState(false);

  useEffect(() => {
    if (location && access) {
      const menuItems = getMenu(access).filter((item) => item?.visible);
      let mainMenu: RouteItem[] = [];
      let subMenu: RouteItem[] = [];

      if (menuItems.length <= 6) {
        mainMenu = menuItems;
      } else {
        menuItems.forEach((item) => {
          if (mainMenu?.length < 5 || !item?.more || hasActiveRoute(item?.path, location.pathname)) {
            mainMenu.push(item);
          } else {
            subMenu.push(item);
          }
        });
      }

      setMenu(mainMenu);
      setMoreMenu(
        subMenu.map((item) =>
          getMenuItem({
            key: `more-${item?.id}`,
            className: `e2e-more-${item?.id}`,
            label: (
              <span id={item?.id}>
                <Trans>{item?.title}</Trans>
              </span>
            ),
            onClick: (e) => navigate(item?.path)
          })
        )
      );
    }
  }, [location, access]);

  return (
    <Navbar border={border}>
      {menu.map(({ title, path, id, active }, i) => (
        <NavbarItem
          key={i}
          type="router"
          to={path}
          className={clsx({ text_lg: size === "lg" })}
          id={id}
          active={active}
        >
          <Trans i18nKey={id as any}>{title}</Trans>
        </NavbarItem>
      ))}
      {!!moreMenu?.length && (
        <NavbarItem
          key="more-menu"
          type="link"
          className={clsx({ text_lg: size === "lg" })}
          id="more-menu"
          active={visibleMore}
        >
          <DropdownButton
            menu={{
              onClick: ({ domEvent }: any) => {
                domEvent.stopPropagation();
              },
              items: moreMenu
            }}
            arrow={false}
            onVisibleChange={setVisibleMore}
          >
            <Trans>Ещё</Trans>
            <Span pl={4}>
              <Icon name={visibleMore ? "arrow-top" : "arrow-bottom"} size={10} />
            </Span>
          </DropdownButton>
        </NavbarItem>
      )}
    </Navbar>
  );
};

export default MainMenu;
