import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_PATH } from "projects/common/shared/config/routes";
import { RouteItem } from "projects/common/types/interfaces/route";
import { Access } from "projects/common/types/interfaces/access";
import Box from "projects/common/shared/ui/typography/Box";
import Skeleton from "projects/common/shared/ui/skeleton/Skeleton";
import { useAppSelector } from "projects/common/app/hooks/useStore";
import { selectAccess, selectUser, selectUserLoaded } from "../entities/user/model";
import { getPageDefault, getRoutesDefault } from "../entities/app/lib";

interface OwnProps {
  getRoutes?: (access: Access) => RouteItem[];
  getPage?: (access: Access) => string;
}

type Props = OwnProps;

const AppRoutes = (props: PropsWithChildren<Props>) => {
  const { getRoutes = getRoutesDefault, getPage = getPageDefault } = props;
  const data = useAppSelector(selectUser);
  const access = useAppSelector(selectAccess);
  const loaded = useAppSelector(selectUserLoaded);
  const [routes, setRoutes] = useState<RouteItem[]>([]);
  const [init, setInit] = useState(false);
  const [defaultPage, setDefaultPage] = useState(ROUTE_PATH.user);

  useEffect(() => {
    if (access) {
      setRoutes(getRoutes(access));
      setDefaultPage(getPage(access));
      setInit(true);
    }
  }, [access]);

  return (
    <Fragment>
      {!init || !loaded || !data || !access ? (
        <Box m={40}>
          <Skeleton title paragraph={{ rows: 4 }} />
        </Box>
      ) : (
        <Routes>
          {routes
            .filter((route) => route?.visible)
            .map(({ id, path, element }) => (
              <Route key={id} path={"/" + path + "/*"} element={element} />
            ))}
          <Route path="*" element={<Navigate replace to={`/${defaultPage}`} />} />
        </Routes>
      )}
    </Fragment>
  );
};

export default AppRoutes;
