import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "projects/common/types/enums/loading";
import { RootState } from "../../app/store";

// Types
export interface ReportState {
  dataIsEdited?: boolean;
  loading: Loading;
}

const initialState: ReportState = {
  dataIsEdited: false,
  loading: Loading.Idle
};

// Reducer
export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setDataIsEdited(state, { payload }: PayloadAction<boolean>) {
      state.dataIsEdited = payload || undefined;
    },
    clear(state) {
      return initialState;
    }
  }
});
export const reportActions = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
export const reportName = reportSlice.name;

// Selectors
export const selectReportState = (state: RootState) => state.report;
export const selectReportDataIsEdited = createSelector(selectReportState, (client: ReportState) => client.dataIsEdited);
