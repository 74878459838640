import { Credential, CredentialState, Department, DepartmentState, User, UserState } from "../../types/models/client";

export const getClientDepartmentMock = (data: Partial<Department> = {}): Department => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name: "Департамент 1",
  description: "Описание департамент",
  version: 1,
  state: DepartmentState.ACTIVE,
  createdAt: "2024-01-26T05:34:56.365Z",
  updatedAt: "2024-01-26T05:34:56.365Z",
  ...data
});

export const getClientCredentialMock = (data: Partial<Credential> = {}): Credential => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  login: "Petr",
  version: 1,
  description: "Что-то интересное о пете",
  state: CredentialState.ACTIVE,
  accessProfile: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Простой пользователь"
  },
  user: getClientUserMock({ id: data.id || "3fa85f64-5717-4562-b3fc-2c963f66afa6" }),
  createdAt: "2024-01-26T05:37:30.417Z",
  updatedAt: "2024-01-26T05:37:30.417Z",
  ...data
});

export const getClientUserMock = (data: Partial<User> = {}): User => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name: "Петр",
  version: 1,
  department: getClientDepartmentMock({ id: data?.id || "3fa85f64-5717-4562-b3fc-2c963f66afa6" }),
  description: "Что-то интересное о пете",
  state: UserState.ACTIVE,
  createdAt: "2024-01-25T06:56:34.082Z",
  updatedAt: "2024-01-25T06:56:34.082Z",
  ...data
});

export default {
  clientDepartment: {
    data: getClientDepartmentMock()
  },
  clientDepartments: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getClientDepartmentMock({ id: "1", name: "Department 1" }),
        getClientDepartmentMock({ id: "2", name: "Department 2" }),
        getClientDepartmentMock({ id: "3", name: "Department 3" }),
        getClientDepartmentMock({ id: "4", name: "Department 4" }),
        getClientDepartmentMock({ id: "5", name: "Department 5", state: DepartmentState.DISABLED }),
        getClientDepartmentMock({ id: "6", name: "Department 6" }),
        getClientDepartmentMock({ id: "7", name: "Department 7" }),
        getClientDepartmentMock({ id: "8", name: "Department 8" }),
        getClientDepartmentMock({ id: "9", name: "Department 9" }),
        getClientDepartmentMock({ id: "10", name: "Department 10" })
      ]
    }
  },
  clientCredential: {
    data: getClientCredentialMock()
  },
  clientCredentials: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getClientCredentialMock({ id: "1", login: "Login 1" }),
        getClientCredentialMock({ id: "2", login: "Login 2" }),
        getClientCredentialMock({ id: "3", login: "Login 3" }),
        getClientCredentialMock({ id: "4", login: "Login 4" }),
        getClientCredentialMock({ id: "5", login: "Login 5", state: CredentialState.DISABLED }),
        getClientCredentialMock({ id: "6", login: "Login 6" }),
        getClientCredentialMock({ id: "7", login: "Login 7" }),
        getClientCredentialMock({ id: "8", login: "Login 8" }),
        getClientCredentialMock({ id: "9", login: "Login 9" }),
        getClientCredentialMock({ id: "10", login: "Login 10" })
      ]
    }
  },
  clientUser: {
    data: getClientUserMock()
  },
  clientUsers: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getClientUserMock({ id: "1", name: "Name 1" }),
        getClientUserMock({ id: "2", name: "Name 2" }),
        getClientUserMock({ id: "3", name: "Name 3" }),
        getClientUserMock({ id: "4", name: "Name 4" }),
        getClientUserMock({ id: "5", name: "Name 5", state: UserState.DISABLED }),
        getClientUserMock({ id: "6", name: "Name 6" }),
        getClientUserMock({ id: "7", name: "Name 7" }),
        getClientUserMock({ id: "8", name: "Name 8" }),
        getClientUserMock({ id: "9", name: "Name 9" }),
        getClientUserMock({ id: "10", name: "Name 10" })
      ]
    }
  }
};
