export enum ListType {
  SIMPLE = "SIMPLE"
}

export enum ListState {
  CREATED = "CREATED",
  CREATED_WITH_ERROR = "CREATED_WITH_ERROR",
  CREATING = "CREATING",
  FAILED = "FAILED",
  DELETED = "DELETED",
  ERROR = "ERROR"
}
