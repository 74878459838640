import { api } from "./api";
import {
  CreateReportRequest,
  Report,
  ReportFileUrl,
  ReportFileUrlWrapper,
  ReportPage,
  ReportPageWrapper,
  ReportWrapper
} from "projects/common/types/models/reports";
import { ReportFiltersType, ReportSortsType } from "projects/common/types/interfaces/report";
import { PageRequest } from "projects/common/types/interfaces/pageResponse";

const prefix = "/reports-gateway/client-area/v1";
export const reportApiUrl = {
  reportCreate: `${prefix}/report`,
  reports: `${prefix}/report/all`,
  reportCancel: (reportId: string) => `${prefix}/report/${reportId}/cancel`,
  reportStart: (reportId: string) => `${prefix}/report/${reportId}/start`,
  reportRecreate: (reportId: string) => `${prefix}/report/${reportId}/recreate`,
  report: (reportId: string) => `${prefix}/report/${reportId}`,
  reportUpdate: (reportId: string) => `${prefix}/report/${reportId}`,
  reportDelete: (reportId: string) => `${prefix}/report/${reportId}`,
  reportDownloadUrl: (reportId: string) => `${prefix}/report/${reportId}/download-url`
};

export const reportApi = api.injectEndpoints({
  endpoints: (build) => ({
    createReport: build.mutation<Report, CreateReportRequest>({
      query: (queryArg) => ({
        url: reportApiUrl.reportCreate,
        method: "POST",
        body: queryArg
      }),
      transformResponse: (response: ReportWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, _req) => [{ type: "Report", id: _result?.id }]
    }),
    getAllReports: build.query<ReportPage, PageRequest>({
      query: (
        { page = 0, size = 10, ...other }: PageRequest<ReportFiltersType, ReportSortsType> = { page: 0, size: 10 }
      ) => ({
        url: reportApiUrl.reports,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: ReportPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(
          ({ id }) =>
            ({
              type: "Report",
              id
            } as const)
        ) || []),
        { type: "Report", id: "LIST" }
      ]
    }),
    cancelReport: build.mutation<Report, string>({
      query: (id) => ({
        url: reportApiUrl.reportCancel(id),
        method: "POST"
      }),
      transformResponse: (response: ReportWrapper, meta, arg) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: report } = await queryFulfilled;
          dispatch(api.util.updateQueryData("getReport" as never, id as never, (draft) => report));
        } catch {}
      },
      invalidatesTags: (_result, _err, id) => [{ type: "Report", id: "LIST" }]
    }),
    startReport: build.mutation<Report, string>({
      query: (id) => ({
        url: reportApiUrl.reportStart(id),
        method: "POST"
      }),
      transformResponse: (response: ReportWrapper, meta, arg) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: report } = await queryFulfilled;
          dispatch(api.util.updateQueryData("getReport" as never, id as never, (draft) => report));
        } catch {}
      },
      invalidatesTags: (_result, _err, id) => [{ type: "Report", id: "LIST" }]
    }),
    recreateReport: build.mutation<Report, string>({
      query: (id) => ({
        url: reportApiUrl.reportRecreate(id),
        method: "POST"
      }),
      transformResponse: (response: ReportWrapper, meta, arg) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: report } = await queryFulfilled;
          dispatch(api.util.updateQueryData("getReport" as never, id as never, (draft) => report));
        } catch {}
      },
      invalidatesTags: (_result, _err, id) => [{ type: "Report", id: "LIST" }]
    }),
    getReport: build.query<Report, string>({
      query: (id) => ({
        url: reportApiUrl.report(id)
      }),
      transformResponse: (response: ReportWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "Report", id }]
    }),
    saveReport: build.mutation<Report, Report>({
      query: (queryArg) => ({
        url: reportApiUrl.reportUpdate(queryArg.id),
        method: "PUT",
        body: queryArg
      }),
      transformResponse: (response: ReportWrapper, meta, arg) => response.data,
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: report } = await queryFulfilled;
          dispatch(api.util.updateQueryData("getReport" as never, id as never, (draft) => report));
        } catch (error: any) {
          if (error?.error?.status === 409) {
            dispatch(api.util.invalidateTags([{ type: "Report", id }]));
          }
        }
      }
    }),
    deleteReport: build.mutation<any, string>({
      query: (id) => ({
        url: reportApiUrl.reportDelete(id),
        method: "DELETE"
      }),
      invalidatesTags: (_result, _err, _req) => [{ type: "Report", id: _result?.id }]
    }),
    downloadReportUrl: build.mutation<ReportFileUrl, string>({
      query: (id) => ({
        url: reportApiUrl.reportDownloadUrl(id),
        method: "GET"
      }),
      transformResponse: (response: ReportFileUrlWrapper, meta, arg) => response.data
    })
  }),
  overrideExisting: false
});

export const {
  useCreateReportMutation,
  useGetAllReportsQuery,
  useCancelReportMutation,
  useStartReportMutation,
  useGetReportQuery,
  useSaveReportMutation,
  useRecreateReportMutation,
  useDeleteReportMutation,
  useDownloadReportUrlMutation
} = reportApi;
