export enum RoleName {
  GlobalAdmin = "global_admin",
  TenantAdmin = "tenant_admin",
  TenantStatist = "tenant_statist",
  DepartmentAdmin = "department_admin",
  DepartmentStatist = "department_statist",
  User = "user",
  SendSmpp = "send_smpp",
  SendMessage = "send_message",
  /**
   * @deprecated Удалить роль по сигналу от бэка
   */
  DeprecatedSenderPatternViewer = "client-area.sender-pattern.viewer.client",
  SmsPatternViewer = "client-area.sms-pattern.viewer.client",
  SmsPatternValidator = "client-area.sms-pattern.validator",
  SenderPatternViewer = "client-area.sender.viewer.client",
  MessageLookup = "client-area.message-lookup.viewer.user",
  SimpleMessageSending = "client-area.simple-message-sending.operator",
  ClientEditor = "client-area.user-model.editor.client",
  ClientViewer = "client-area.user-model.viewer.client",
  DepartmentEditor = "client-area.user-model.editor.department",
  DepartmentViewer = "client-area.user-model.viewer.department"
}
