/*
 * Переводчик:
 * https://www.translate.ru/%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4/%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9
 * Formatting resources:
 * ^([А-яA-z0-9]{1}[А-яA-z \.\,\\\/0-9\%\:\-\+]+[А-яA-z0-9\.\%\…]{1})\s-\s([A-z0-9]{1}[A-z \’\.\,\\\/0-9\%\:\-\+]+[A-z\.0-9\%\”]{1})$
 * "$1": "$2",
 *
 * Replace in code:
 * const t = useTranslation();
 * {t($0)}
 * Trans
 * */
export const TRANSLATION_RESOURCES = {
  // Keys
  // - Main Menu
  "menu-campaigns": "Campaigns",
  "menu-lists": "Lists",
  "menu-reports": "Reports",
  "menu-audit": "Action audit",
  "menu-message-lookup": "Activity log",
  "menu-names": "Search",
  "menu-preset": "Presets",
  "menu-sms-patterns": "SMS templates",
  "file-max-size": ". Maximum attachment size up to", // File Uploader
  "channel-lifetime": `Message <br /> lifetime`, // Настройки канала "Время жизни <br /> сообщения"
  "preset-select": `Add`, // Кнопка "Выбрать" в модальнок окне
  "change": `Change`, // Создание рассылки. Перевод не совпадает с названием
  "sms-pattern-filter-template": `Template`, // Шаблоны СМС. Фильтр поиска
  "sms-pattern-filter-text": `Message`, // Шаблоны СМС. Фильтр поиска
  "select-request": `Not all entries shown. Use the search`, // Выпадашка с поиском. Когда показаны не все записи в фильтре

  // -------------------------------------------------------------------
  // Общие термины
  "Рассылки": "Campaigns",
  "Отчеты": "Reports",
  "Отчёты": "Reports",
  "Аудит действий": "Audit of actions",
  "Поиск сообщений": "Search",
  "Пресеты": "Presets",
  "Шаблоны SMS": "SMS templates",
  "Все": "All",
  "Черновик": "Draft",
  "Создается": "Being created",
  "Выполняется": "In progress",
  "Приостановлена": "On pause",
  "Отменена": "Cancelled",
  "Выполнена": "Executed",
  "Завершена": "Finished",
  "С ошибками": "With errors",
  "Дата создания": "Created at",
  "Дата изменения": "Updated at",
  "Дата активации": "Activation at",
  "Дата удаления": "Deletion at",
  "Название": "Name",
  "Статус": "Status",
  "Имя отправителя": "Sender name",
  "Канал": "Channel",
  "Каналы": "Channels",
  "Выполнено, %": "Completed, %",
  "Фильтры": "Filters",
  "Дата": "Date",
  "Пользователи": "Users",
  "Все пользователи": "All users",
  "Раздел": "Section",
  "Все разделы": "All sections",
  "с": "from",
  "по": "to",
  "от": "from",
  "Нет совпадений": "No matches",
  "Изменить": "Edit",
  "Дублировать": "Duplicate",
  "Запустить": "Run",
  "Остановить": "Stop",
  "Удалить": "Delete",
  "Выбрано": "Selected",
  "Выполнено %": "Completed %",
  "Таблица пуста": "The table is empty",
  "Загрузка данных": "Loading data",
  "По запросу": "Via request",
  "ничего не найдено": "nothing found",
  "Вы уверены что хотите подтвердить?": "Are you sure you want to confirm?",
  "Да": "Yes",
  "Отменить": "Cancel",
  "Сохранить": "Save",
  "Сегодня в": "Today at",
  "Вчера в": "Yesterday at",
  "янв": "jan",
  "фев": "feb",
  "мар": "mar",
  "апр": "apr",
  "май": "may",
  "июнь": "jun",
  "июль": "jul",
  "авг": "aug",
  "сен": "sep",
  "окт": "oct",
  "ноя": "nov",
  "дек": "dec",
  "Создать": "Create",
  "Понедельник": "Monday",
  "Вторник": "Tuesday",
  "Среда": "Wednesday",
  "Четверг": "Thursday",
  "Пятница": "Friday",
  "Суббота": "Saturday",
  "Воскресенье": "Sunday",
  "Пн": "Mon",
  "Вт": "Tue",
  "Ср": "Wed",
  "Чт": "Thu",
  "Пт": "Fri",
  "Сб": "Sat",
  "Вс": "Sun",
  "Текст": "Text",
  "Комментарий": "Comment",
  "Создан": "Created at",
  "Изменён": "Updated at",
  "Обрабатывается": "Being processed",
  "Ок": "Ok",
  "Ошибка": "Error",
  "Требуется действие": "Action required",
  "Отменен": "Cancelled",
  "Добавить": "Add",
  "Старт": "Start",
  "Выбрать дату": "Select date",
  "сохранен": "saved",
  "Без текста": "Without text",
  "изображение": "image",
  "Добавьте": "Add",
  "Заголовок": "Title",
  "сообщения": "messages",
  "Изображение": "Image",
  "Доступно": "Available",
  "Тип": "Type",
  "Скачать": "Download",
  "Редактировать": "Edit",
  "Выберите": "Select",
  "Выберите файл": "Select a file",
  "МБ": "MB",
  "мб": "mb",
  "до": "before",
  "м": "m",
  "ч": "h",
  "или": "or",
  "Выбрать файл": "Select file",
  "Превышен максимальный размер файла": "Maximum file size exceeded",
  "Максимальный размер": "Maximum size",
  "Некорректный файл": "Unsupported file content",
  "Любое": "Any",
  "Любая": "Any",
  "Любой": "Any",
  "Нет": "No",
  "Есть": "Yes",
  "сек.": "sec.",
  "Перейти к": "Go to",
  "Ещё": "More",

  // -------------------------------------------------------------------
  // Login
  "Логин": "Login",
  "Введите ваш логин": "Enter login",
  "Пароль": "Password",
  "Введите ваш пароль": "Enter password",
  "Войти": "Log in",

  // Учётная запись
  "Глобальный администратор": "Global administrator",
  "Администратор организации": "Organization administrator",
  "Статист организации": "Organization statist",
  "Администратор департамента": "Department administrator",
  "Статист департамента": "Department statist",
  "Пользователь": "User",
  "Учётная запись": "User account",
  "Организация": "Organization",
  "Данные пользователя": "User details",
  "Персональные данные": "Personal data",
  "Контактная информация": "Contact details",
  "Департамент": "Department",
  "Роль": "Role",
  "ФИО": "Full name",
  "Часовой пояс": "Timezone",
  "Телефон": "Mobile number",
  "Ни разу не менялся": "Never modified",
  "Электронная почта": "Email",
  "Текущий пароль": "Current password",
  "Новый пароль": "New password",
  "Минимальная длина 8 символов": "Minimum length 8 characters",
  "час": "hour",

  // Рассылки
  // - Перевод рабочей области под вторым слоем меню:
  "Создать рассылку": "New campaign",
  "Рассылок": "Campaigns",
  "Удалить выбранные рассылки?": "Delete selected campaigns?",
  "Выбранные рассылки удалены": "Selected campaigns have been deleted",
  "Рассылка удалена": "Campaign deleted",
  "Создайте первую рассылку": "Create your first campaign",
  "Все ваши рассылки будут хранится тут": "Your campaigns will be stored here",
  // - Перевод интерфейса по кнопке “Создать рассылку”
  "Создание рассылки": "Creating campaign",
  "Тип рассылки": "Type",
  "Обычная": "Standard",
  "Индивидуальные сообщения": "Personalized messages",
  "С подстановками": "With substitution",
  "Индивидуальная": "Personalized",
  "Из файла": "From file",
  "Вставьте ссылку": "Image URL",
  "Загрузите с устройства": "Upload",
  "Вернуть базовый текст": "Revert to the original text",
  // - Перевод интерфейса - Создание рассылки
  "Создание рассылки с индивидуальными сообщениями": "Create a campaing with personalized messages",
  "Создание рассылки с подстановками": "Create a campaing with substitutuons",
  "Создание обычной рассылки": "Create a standard campaign",
  "Новая рассылка от": "New campaign",
  "Дата старта и завершения": "Campaign start and finish date",
  "Рассылка сообщений": "Delivery scheme",
  "Как можно быстрее": "Start as soon as possible",
  "Начнём сразу и остановимся после исполнения": "Execute immediately and stop upon finish",
  "С указанной даты": "Start from the specified date",
  "Начнем в указанную дату, остановимся после исполнения": "Start on the specified date, stop after execution",
  "В диапазоне дат": "In specified date range",
  "Начнем и остановимся в указанные даты": "Start and stop at the specified dates",
  "Дни": "Days",
  "Время отправки": "Scheduled for a specific time",
  "Локальное время абонента": "Recipient time zone",
  "учитывать": "consider",
  "Не учитывать": "Don’t consider",
  "Базовый текст сообщения": "Message text",
  "Ввести": "Submit",
  "Сообщение": "Message text",
  "Выбрать пресет": "Select preset",
  "Сохранить в пресет": "Save to presets",
  "Перевести в транслит": "Transliteration",
  "В транслит": "Transliteration",
  "символов": "characters",
  "СМС": "SMS",
  "Выберите каналы": "Choose channels",
  "Выбрать": "Select",
  "Выбор канала доставки": "Select delivery channels",
  "Получатели": "Recipients",
  "Максимальное время жизни сообщения": "Max message lifetime",
  "Время жизни": "Lifetime",
  "минут": "minutes",
  "Стоп-лист": "Stop list",
  "Список": "List",
  "Файл": "File",
  "Стоп-лист ручного ввода": "Manual entry stop list",
  "Ручной ввод": "Manual input",
  "Получатели…": "Recipients...",
  "Стоп-лист…": "Stop list",
  "Добавление получателей": "Adding recipients",
  "Загрузить файл": "Upload file",
  "Ввести вручную": "Manual input",
  "Перетащите файл в это поле или кликните для загрузки": "Drag and drop the file into this field or click to download",
  "Перетащите файлы в это поле или кликните для загрузки":
    "Drag and drop the files into this field or click to download",
  "Введите номер телефона по маске +79998887766":
    "When adding international phone numbers, type the + sign, then the country code followed by the local number.",
  "Каждый номер необходимо вводить с новой строки": "Each phone number must be entered from a new line",
  "Добавление стоп-листов": "Add a stop-list",
  "Скорость рассылки": "Messaging speed",
  "Рассылать с максимальной скоростью": "Send at maximum speed",
  "Кол-во абонентов информированных в минуту": "Number of informed recipients per minute",
  "Общая готовность": "Overall readiness",
  "Завершение": "Finish",
  "Каналы доставки": "Delivery channels",
  "Дни недели": "Days of the week",
  "Время завершения": "Completion time",
  "Запуск рассылки": "Campaign start",
  "Осталось": "Remaining steps",
  "Добавить базовый текст": "Add message text",
  "Настроить каналы": "Set-up delivery channels",
  "Выбрать получателей": "Add recipients",
  "Отменить запуск": "Cancel Start",
  "Ожидание отправки по расписанию": "Pending",
  "Отправлено": "Sent",
  "Доставлено": "Delivered",
  "Не доставлено": "Not delivered",
  "Просмотрено": "Seen",
  "Подготовка": "Preparation",
  "Исполнение": "Execution",
  "Подготовка отчета": "Report preparation",
  "Запустить рассылку": "Start campaign",
  "Название рассылки": "Campaign Name",
  "Начнем": "Start",
  "Остановимся": "Stop",
  "с момента старта": "from the moment of start",
  "Остановимся после исполнения": "Stop after execution",
  "Как можно быстрее. Начнем сразу и остановимся после исполнения":
    "As soon as possible. Start right away and stop after execution",
  "Дата старта рассылки": "Campaign start date",
  "Начало": "Beginning",
  "Учитывать": "Consider",
  "Выберите дни недели": "Select days of the week",
  "с учетом": "taking",
  "без учета": "not taking",
  "локального времени абонента": "into account the recipient time zone",
  "Введите текст": "Enter text",
  "Теги": "Tags",
  "160 символов для латиницы, 70 символов для русского": "160 characters for Latin, 70 characters for Cyrillic",
  "При пересылке длинного СМС размеры его частей уменьшаются до 153 и 67 символов соответственно.":
    "When sending a long SMS, the size of its parts is reduced to 153 and 67 characters, respectively.",
  "Пресет": "Preset",
  "Изменение пресета": "Change a preset",
  "Создание пресета": "Create a preset",
  "По желанию": "Optional",
  "Выбор пресета": "Preset selection",
  "Название, текст пресета или комментарий": "Name, Preset text or Comment",
  "Попробуйте ввести другой текст или проверьте фильтры": "Try typing different text or check the filters",
  "Не удалось ничего найти": "Nothing found",
  "Каскадный тип доставки": "Cascading delivery type",
  "Если сообщение не будет доставлено на первый канал, оно будет отправлено на следующий":
    "If the message is not delivered to the first channel, it will be sent to the next",
  "Выбран один канал доставки": "One delivery channel selected",
  "Выберите имя отправителя и время жизни сообщения для всех каналов":
    "Select the sender name and message lifetime for all channels",
  "Для настройки каналов необходимо ввести текст базового сообщения":
    "To configure channels, you must enter the base message text",
  "У Вас нет доступных каналов доставки": "No available delivery channels",
  "Альтернативный текст": "Alternate text",
  "Базовый текст": "Base text",
  "кнопка": "button",
  "от имени": "on behalf of",
  "Такую конфигурацию отправить нельзя. Добавьте текст, кнопку или изображение":
    "This configuration cannot be sent. Add text, button, or image ",
  "Такую конфигурацию отправить нельзя. Добавьте текст или изображение":
    "This configuration cannot be sent. Add text or image ",
  "Такую конфигурацию отправить нельзя. Добавьте кнопку или уберите изображение":
    "This configuration cannot be sent. Add a button or remove the image ",
  "Такую конфигурацию отправить нельзя. Уберите действие или кнопку":
    "This configuration cannot be sent. Remove the action or button",
  "Такую конфигурацию отправить нельзя. Добавьте текст или уберите кнопку":
    "This configuration cannot be sent. Add text or remove the button ",
  "Такую конфигурацию отправить нельзя. Добавьте текст": "This configuration cannot be sent. Add text ",
  "символов из": "characters from",
  "Кнопку": "Button",
  "Предварительный просмотр найденых ссылок в сообщении": "Preview found links in message",
  "Отключить предварительный просмотр ссылок": "Turn off link preview",
  "Показать, если найдена": "Show if found",
  "Отправитель": "Sender",
  "Отправители": "Senders",
  "URL картинки": "Image URL",
  "Кнопки": "Buttons",
  "Кнопка": "Button",
  "При загрузке списка отправителей возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the sender list, please try again",
  "Выберите отправителя": "Choose the sender",
  "Ссылка": "Link",
  "Добавить кнопку": "Add button",
  "кнопки из": "buttons from",
  "Источник": "Source",
  "Стоп-листы": "Stoplists",
  "Добавьте получателей. Добавьте стоп-листы, при необходимости.": "Add recipients. Add stoplists, if necessary. ",
  "Предварительный просмотр": "Preview",
  "Уникальных получателей": "Unique recipients",
  "Вычисляем общее количество получателей": "Calculate the total number of recipients",
  "Найдено ошибок": "Errors found",
  "Обработано записей": "Records processed",
  "Будет добавлено": "Will be added",
  "Проверить и подтвердить": "Check and confirm",
  "Игнорировать ошибки": "Confirm import with errors",
  "Скачать файл с ошибками": "Download file with errors",
  "Отменить импорт файла": "Undo File Import",
  "Введите номера телефонов по маске\n+79998887766\nКаждый номер необходимо вводить с новой строки":
    "Enter phone numbers by mask\n + 79998887766\nEach number must be entered from a new line",
  "Не удалось загрузить данные, попробуйте еще раз": "Unable to load data, please try again",
  "Редактирование списка получателей": "Edit recipient list",
  "Форматы": "Formats",
  "Строка": "String",
  "Кодировка файла верная": "The file encoding is correct",
  "Подтвердить импорт": "Confirm Import",
  "Закрыть": "Close",
  "Будут добавлены": "Will be added",
  "Не будут добавлены": "Will not be added",
  "Для предварительного просмотра сообщения необходимо выбрать канал доставки":
    "You must select a delivery channel to preview the message",
  "Кол-во абонентов информ.в мин": "Number of recipients informed per min",
  "Количество абонентов информируемых в минуту": "Number of recipients informed per minute",
  "Максимальная": "Maximum",
  "Ввести название рассылки": "Enter campaign name",
  "Указать даты и выбрать дни недели": "Specify dates and select days of the week",
  "Указать скорость": "Specify speed",
  "абонент": "recipient",
  "абонента": "recipient",
  "абонентов": "recipients",
  "в минуту": "per minute",
  "Как можно раньше": "As soon as possible",
  "Без ограничений": "Unlimited",
  "с учётом локального времени абонента": "taking into account the local time of the recipient",
  "Приостановлена из-за ошибки": "Paused due to error",
  "Удалена": "Deleted",
  "Мы готовы разослать сообщения. Ждем команду": "We are ready to send out messages. Waiting for the command to start.",
  "Отменить остановку": "Undo stop",
  "Остановить рассылку": "Stop campaign",
  "Старт рассылки запланирован на": "The campaign is scheduled to start at",
  "Ждем": "Waiting",
  "в": "in",
  "Рассылаем сообщения": "Sending messages",
  "Ждем запуска рассылки": "Waiting for the campaign to start",
  "Рассылка создается": "Campaign is being created",
  "Сокращение ссылок": "Link Shortener",
  "Включено": "Enabled",
  "Выключено": "Turned off",
  "Использовать сокращение ссылок в тексте": "Use link shortening in text",
  "Ссылки в текстах будут заменены на короткие": "Links in texts will be replaced with short ones",
  "Сокращение ссылок в текстах включено": "Short links in texts enabled",
  "Сокращение ссылок в текстах выключено": "Short links in texts off",
  "Включите опцию сокращения ссылок, чтобы ссылки в текстах сообщений заменились на короткие ссылки":
    "Turn on the link shortening option so that links in message texts are replaced with short links",
  "Показать скрытое": "Show hidden",
  "Действие при нажатии на PUSH": "Action when you press PUSH",
  "заголовок": "title",
  "действие": "action",
  "Период для формирования отчета": "Period for report generation",
  "Выберите рассылки": "Select campaigns",
  // - Перевод интерфейса - Просмотр рассылки
  "Подготовить подробный отчёт": "Prepare a detailed report",
  "Исполнение рассылки": "Campaign execution",
  "с индивидуальными текстами": "with personalized texts",
  "Детали рассылки": "Campaign details",
  "Прогресс выполнения": "Progress of execution",

  // Отчеты
  // - Список отчетов
  "Тип отчета": "Report type",
  "Период": "Period",
  "Отчет": "Report",
  "Готовится": "Preparing",
  "Не выполнен": "Failed",
  "В архиве": "Archived",
  "Восстановить": "Restore",
  "Восстановить из архива": "Restore from archive",
  "Удалить выбранные отчеты?": "Remove the selected reports?",
  "Выбранные отчёты удалены": "The selected reports have been deleted",
  "Отчёт удален": "Report deleted",
  "Создать отчёт": "Create report",
  "Отчётов": "Reports",
  "Создайте первый отчет": "Create the first report",
  "Все ваши отчеты будут хранится тут": "All your reports will be stored here",
  "Сводный": "Summary report",
  "Детализированный": "Detailed report",
  "По выбранным рассылкам": "Campaigns report",
  "Финансовая справка": "Financial statement",
  "Создание нового отчёта": "Create a new report",
  "Тип отчёта": "Report type",
  "Без критериев группировки": "Without grouping criteria",
  "Финансовая справка от": "Financial statement from",
  "Отчет о переходах по коротким ссылкам от": "Short link clicks report from",
  // - Создание отчетов
  "Готов": "Ready",
  "Источник трафика": "Traffic sources",
  "Список рассылок": "Campaign list",
  "Фильтр по статусу": "Filters by status",
  "Ожидает отправки": "Pending",
  "Просрочено": "Expired",
  "сообщение принято платформой": "message received by the platform",
  "сообщение не может быть обработано": "message cannot be processed",
  "отправка сообщения запланирована по расписанию": "scheduled to send message",
  "отправка сообщения по расписанию отменена": "scheduled message canceled",
  "статус сообщения не получен в течение TTL": "message status not received during ttl",
  "статус сообщения неизвестен": "message status unknown",
  "Отменить подготовку": "Cancel preparation",
  "Подготовить отчёт": "Prepare report",
  "Подготовка отчёта": "Report preparation",
  "Подтвердить и подготовить": "Confirm and prepare",
  "Отчёт готов": "Report ready",
  "Создание детализированного отчёта": "Create traffic report",
  "Подготовка детализированного отчёта": "Preparing traffic report",
  "Отчёт детализированный": "Traffic report",
  "Создание отчёта по выбранным рассылкам": "Create report for selected campaigns",
  "Подготовка отчёта по выбранным рассылкам": "Preparing selected campaign report",
  "Отчёт по выбранным рассылкам": "Selected campaigns report",
  "Создание сводного отчёта": "Create summary report",
  "Подготовка сводного отчёта": "Preparation of summary report",
  "Сводный отчёта": "Summary report",
  "Создание финансовой справки": "Creating a financial statement",
  "Подготовка финансовой справки": "Preparation of financial statement",
  "Сводный отчёт": "Summary report",
  "Создание отчета о переходах по коротким ссылкам": "Creating a short link clicks report",
  "Подготовка отчета о переходах по коротким ссылкам": "Preparation of short link clicks report",
  "Отчет о переходах по коротким ссылкам": "Short link clicks report",
  "-й": "",
  "-м": "",
  "-и": "",
  "рассылке": "campaign",
  "рассылкам": "campaigns",
  "Отчет по": "Report to",
  "Отчет по выбранным рассылкам": "Report for selected campaigns",
  "Отчет детализированный": "Detailed report",
  "Получателей": "Recipients",
  "получателей": "recipients",
  "Выберите одну или несколько рассылок для подготовки отчёта": "Select one or more campaigns to report on",
  "Выбор рассылок для отчёта": "Select campaigns for report",
  "Выберите источник траффика, чтобы подготовить отчёт": "Select a traffic source to prepare a report",
  "Выберите диапазон, чтобы подготовить отчёт": "Select a range to prepare a report",
  "Выберите каналы, чтобы подготовить отчёт": "Select channels to report",
  "Выберите статусы сообщений, чтобы подготовить отчёт": "Select message statuses to report",
  "Подготавливаем отчёт": "Preparing a report",
  "Страницу можно закрыть. Данные сохранятся в": "You can close the page. data will be saved in",
  "списке отчётов": "list of reports",
  "Детали отчёта": "Report details",
  "Отчет по рассылкам от": "Campaign report from",
  "Отчет детализированный от": "Detailed report from",
  "Сводный от": "Summary report from",
  "Показать все": "Show all",
  "Скрыть": "Hide",
  "Фильтр по": "Filter by",
  "статусу сообщений": "message status",
  "Дата и время": "Date and time",
  "Объект аудита": "Audit object",
  "Действие": "Action",
  "Изменение": "Change",
  "Группировка": "Grouping",
  "Пользователь (логин)": "User",
  "Страна": "Country",
  "Рассылка": "Campaign",
  "Шаблон": "Message template",
  "Только с шаблонами": "Only with templates",
  "Месяц": "Month",
  "Создание финансовой справки не доступно, обратитесь в службу поддержки":
    "Creating a financial statement is not available, please contact support",
  "Обновить": "Refresh",
  "Выберите департамент": "Choose a department",
  "Выберите автора": "Choose author",
  "Выберите канал": "Choose channel",
  "Выберите логин": "Choose login",
  "Выберите источник": "Choose source",
  "Выберите статус": "Choose status",
  "Нет доступных фильтров": "No filters available",
  "У Вас нет доступных отчетов": "You have no reports available",

  // Аудит
  // - Список действий
  "Аутентификация": "Authentication",
  "Создание": "Create",
  "Удаление": "Delete",
  "Копирование": "Copy",
  "Запуск": "Start",
  "Остановка": "Stop",
  "Отмена": "Cancel",
  "Получатели загружены": "Recipients loaded",
  "Получатели удалены": "Recipients deleted",
  "Получатели подтверждены": "Recipients confirmed",
  "Вход в личный кабинет": "Login",
  "Выход из личного кабинета": "Logout",
  "Неудачная попытка входа в личный кабинет": "Login attempt failed",
  "Измените выборку, чтобы снизить количество записей меньше 1 млн":
    "Change the sample to reduce the number of records less than 1 million",
  "Действий": "Actions",
  "Очистить фильтры": "Clear filters",
  "Все ваши действия будут хранится тут": "All your actions will be stored here",
  "Действия": "Actions",
  "Подробная информация": "Details",
  "Списки абонентов": "Lists",
  // - Фильтры действий
  "Открыть отчет": "Open report",
  "Открыть рассылку": "Open campaign",
  "Очистить": "Clear",
  "Искать": "Search",

  // Сообщения
  // - Список сообщений
  "Сообщения": "Messages",
  "Все ваши сообщения будут хранится тут": "All your messages will be stored here",
  "Информация о сообщении": "Message information",
  "Копировать данные сообщения": "Copy message data",
  "Текст сообщения": "Message text",
  "Сообщение скопировано": "Message copied",
  "При копировании возникла ошибка, попробуйте еще раз": "An error occurred while copying, please try again",
  "Начните вводить имя": "Start typing a name",
  "Загрузка данных...": "Loading data...",
  "Введите": "Enter",
  "Все источники": "All sources",
  "Сегментов": "Segments",
  "Транзакционный": "Transactional",
  "Транзакционный (Viber)": "Transactional (Viber)",
  "Инициировано компанией": "Initiated by company",
  "Международный": "International",
  "Мультиподпись": "Multisignature",
  "Информационный": "Informational",
  "Идентификационный": "Identifier",
  "Сервисный": "Service",
  "Авторизационный": "Authorization",
  "Международное имя": "International name",
  "Рекламный": "Promotional",
  "Рекламный шаблонированный": "Promotional template",
  "Нешаблонированный": "Not template",
  "Инициировано пользователем": "Initiated by user",
  "Количество сообщений больше лимита выгрузки": "The number of messages exceeds the upload limit",
  "Поиск сообщений от": "Activity log from",
  "Повторное обновление возможно через": "Updating is possible via",

  // Пресеты
  // - Список пресетов
  "Автор": "Author",
  "Удалить выбранные пресеты?": "Delete selected presets?",
  "Удалить пресет": "Delete preset",
  "Выбранные пресеты удалены": "Selected presets have been deleted",
  "Пресет удален": "Preset deleted",
  "Создать пресет": "Create preset",
  "Создайте первый пресет": "Create the first preset",
  "Все ваши пресеты будут храниться тут": "All your presets will be stored here",
  "Включен": "Enabled",
  "Отключен": "Disabled",
  "Удален": "Deleted",
  "Отключить": "Disable",
  "Информация о пресете": "Preset information",
  "Все департаменты": "All departments",
  "Все авторы": "All authors",
  "Все клиенты": "All clients",
  "Все статусы": "All statuses",
  "Включить": "Enable",
  "Выберите клиента": "Choose a client",
  "Начните вводить департамент": "Start entering the department",
  "Начните вводить автора": "Start typing author",

  // Имена
  // - Список имен
  "Оператор": "Operator",
  "Юр.лицо": "Company",
  "Юр.лицо или ИНН": "Company or INN",
  "Компания": "Company",
  "ИНН": "INN",
  "Имя": "Name",
  "Тип ": "Type",
  "Статус ": "Status",
  "Имён": "Names",
  "Имена": "Names",
  "МН": "IN",
  "МП": "MU",
  "АП": "SU",
  "не указан": "nothing",
  "Активно": "Active",
  "Удалено": "Disabled",
  "Информация о имени": "Name information",
  "Клиент": "Client",
  "Все типы": "All types",
  "Все имена": "All names",
  "Все операторы": "All operators",
  "Все каналы": "All channels",
  "Тип трафика": "Traffic type",
  "Тип имени": "Name type",
  "Активация": "Activation",
  "На согласовании": "Under approval",
  "Запланирована активация": "Activation scheduled",
  "Запланировано удаление": "Scheduled to be deleted",
  "Отклонено": "Rejected",
  "Локальное": "Local",
  "Международное": "International",
  "Начните вводить": "Start typing",
  "Имена от": "Names from",
  "Все имена будут хранится тут": "All names will be stored here",
  "Поиск по имени отправителя": "Search by sender name",
  "Введите название или ИНН": "Enter name or INN",

  // Шаблоны SMS
  "Поиск по шаблону": "Search by template",
  "Поиск по тексту сообщения": "Search by message",
  "Юр. лицо": "Company",
  "Информация о шаблоне": "Template information",
  "Текст шаблона": "Template text",
  "Проверить шаблон": "Check template",

  // Проверка шаблона
  "Текст сообщения не соответствует заданному шаблону": "The message text does not match the template",
  "Текст сообщения соответствует заданному шаблону": "The message text matches the template",
  "Проверка шаблона": "Template check",
  "Проверка соответствия": "Template check",
  "Введите текст сообщения": "Enter the message text",
  "Сравнить": "Compare",
  "Ошибка проверки. Попробуйте еще раз или обратитесь в поддержку": "Check Error. Try again or contact support",
  "К сожалению, на данный момент невозможно загрузить список шаблонов. Попробуйте позднее.":
    "Sorry, we are unable to load the template list at this time. Try again later.",

  // Списки
  // - Список списков
  "Поиск по названию": "Search by name",
  "Создайте первый список": "Create the first list",
  "Все ваши списки будут хранится тут": "All your lists will be stored here",
  "Создать список": "Create list",
  "Списков": "Lists",
  "Создание обычного списка": "Create standard list",
  "Удалить выбранные списки?": "Delete selected lists?",
  "Удалить список": "Delete list",
  "Выбранные списки удалены": "The selected lists have been deleted",
  "Список удален": "List deleted",
  "Абоненты": "Recipients",
  "Ошибки списка": "List errors",
  "Комментарий, по желанию": "Comment, optional",
  "Список абонентов успешно создан": "Recipient list created successfully",
  "Введите название": "Enter a name",
  "Нажмите, чтобы посмотреть детали ошибки": "Click to view details of the error",
  "Обработка большого списка может занять время": "Processing a large list may take time",
  "Выбрать из списка": "Select from list",
  "Название списка": "Search by name",
  "Обычный": "Standard",
  "Все списки": "All lists",

  // Быстрые сообщение
  "Отправка сообщений": "Simple message",
  "Введите отправителя": "Enter sender",
  "Номер не соответствует формату": "Number does not match the format",
  "Сообщение поставлено в очередь отправки": "The message is queued for sending",
  "Создание сообщения": "Creating a community",
  "Отправить сообщение": "Send message",
  "Отложить отправку": "Postpone sending",
  "Учитывать локальное время абонента": "Take into account local time of subscriber",
  "Отправить": "Send",

  // Управление
  "profileId": "Access<br/>Profile",
  "Управление": "Management",
  "Департаменты": "Departments",
  "Работает": "Working",
  "Не работает": "Not working",
  "Описание": "Description",
  "Дата добавления": "Date created",
  "Создайте первого пользователя": "Create the first user",
  "Все ваши пользователи будут хранится тут": "All your users will be stored here",
  "Запись успешно сохранена": "Record saved successfully",
  "Запись успешно добавлена": "Record added successfully",
  "Добавить нового пользователя": "Add new user",
  "Добавить учетную запись": "Add account",
  "К сожалению, на данный момент невозможно выполнить операцию. Попробуйте позднее.":
    "Sorry, it is not possible to perform this operation at the moment. Please try again later.",
  "Пароль к учетной записи": "Password to account",
  "Пароль скопирован": "Password copied",
  "Копировать": "Copy",
  "Введите логин": "Enter login",
  "Изменение департамента": "Change department",
  "Создание департамента": "Create department",
  "Информация о пользователе": "User information",
  "Учетные записи": "Accounts",
  "Изменение пользователя": "Change user",
  "Сбросить пароль": "Reset password",
  "Вы уверены, что хотите сбросить пароль?": "Are you sure you want to reset the password?",
  "Продолжить": "Continue",
  "Профиль доступа": "Access profile",
  "Учетные записи пользователя": "User accounts",
  "Создайте первую учетную запись": "Create the first account",
  "Все ваши учетные записи будут хранится тут": "All your accounts will be stored here",
  "Сбросить": "Reset",
  "Редактировать учетную запись": "Edit account",
  "Информация о департаменте": "Department information",

  // -------------------------------------------------------------------
  // Ошибки:
  // - Ошибки валидации под полями:
  "Максимальное кол-во символов": "Maximum number of characters",
  "Обязательное поле": "Required field",
  // - ERROR_MESSAGE (Ошибки запросов)
  "Не удалось запустить генерацию файла, пожалуйста попробуйте еще раз":
    "Unable to start file generation, please try again",
  "Не удалось скачать файл, пожалуйста попробуйте еще раз": "Unable to download file, please try again",
  "Не удалось загрузить файл, пожалуйста попробуйте еще раз": "Failed to upload file, please try again",
  "Размер файла превышает максимально возможный. Используйте другой файл.":
    "The file size exceeds the maximum size. Please use another file.",
  "Ваша версия отчета устарела, мы обновили данные, попробуйте еще раз":
    "Your version of the report is out of date, we have updated the data, please try again",
  // - ERROR_CODE (Ошибки запроса по коду с бэка)
  "Доступ с данного IP запрещен": "Access denied from this IP",
  "Непредвиденная ошибка, пожалуйста попробуйте еще раз": "Unforeseen error, please try again.",
  "Слишком большой объем файла": "File size too large.",
  "Ошибка контроля версий": "Version control error.",
  "Количество выбранных записей превышает допустимый лимит количества строк excel":
    "The number of selected records exceeds the allowed number of excel rows",
  // - ERROR_STATUS (Ошибки запроса по статусу с бэка)
  "Ошибка соединения. Проверьте Ваше подключение к интернету":
    "Connection error. Please check your internet connection",
  "У Вас закончилась сессия, пожалуйста, авторизуйтесь заново": "Your session has expired, please log in again",
  "Доступ запрещен": "Access denied",
  "Размер файла превышает максимально допустимый. Используйте другой файл.":
    "File size exceeds maximum allowed size. Use another file.",
  // - audit.ts
  "При загрузке списка действий возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the action list, please try again",
  "При загрузке списка пользователей возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the user list, please try again",
  "При загрузке списка секций возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the section list, please try again",
  // - auth.ts
  "При выходе из системы возникла ошибка, попробуйте еще раз": "There was an error when I logged out, try again",
  "При загрузке списка рассылок возникла ошибка, попробуйте еще раз":
    "There was an error loading the campaign list, please try again",
  "При авторизации возникла ошибка, попробуйте еще раз": "An error occurred while logging in, please try again",
  "Вы указали неправильный логин/пароль": "You entered the wrong username/password",
  // - campaign.ts
  "При создании рассылки возникла ошибка, попробуйте еще раз":
    "An error occurred while creating the campaign, please try again",
  "При загрузке рассылки возникла ошибка, попробуйте еще раз":
    "There was an error loading the campaing please try again",
  "При обновлении рассылки возникла ошибка, попробуйте еще раз":
    "An error occurred while updating the campaign, please try again",
  "Ваша версия рассылки устарела, мы обновили данные, попробуйте еще раз":
    "Your version of the campaign is out of date, we have updated the data, please try again",
  "При добавления источника рассылки рассылки возникла ошибка, попробуйте еще раз":
    "An error occurred while adding a campaign list source, please try again",
  "При запуске рассылки возникла ошибка, попробуйте еще раз":
    "An error occurred while launching the campaign, please try again",
  "При удалении рассылки возникла ошибка, попробуйте еще раз":
    "An error occurred while deleting the campaign, please try again",
  "При дублировании рассылки возникла ошибка, попробуйте еще раз":
    "An error occurred while duplicating the campaign list, please try again",
  "При удалении источника возникла ошибка, попробуйте еще раз":
    "An error occurred while deleting the source, please try again",
  "При обновлении источника возникла ошибка, попробуйте еще раз":
    "An error occurred while updating the source, please try again",
  "При загрузке списка получателей возникла ошибка, попробуйте еще раз":
    "There was an error loading the recipient list, please try again",
  "При создании отчета возникла ошибка, попробуйте еще раз":
    "There was an error generating the report, please try again",
  "При загрузке списка каналов возникла ошибка, попробуйте еще раз":
    "There was an error loading the channel list, please try again",
  // - message-lookup.ts
  "При загрузке списка сообщений возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the message list, please try again",
  "Не удалось загрузить клиентов, попробуйте еще раз": "Failed to load clients, please try again",
  // - names.ts
  "При загрузке списка имен возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the name list, please try again",
  // - preset.ts
  "При загрузке списка пресетов возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the presets list, please try again",
  "При создании пресета возникла ошибка, попробуйте еще раз":
    "An error occurred while creating the preset, please try again",
  "Такое имя пресета уже используется в вашей организации, выберите другое": "Duplicated preset name",
  "При загрузке пресета возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the preset, please try again",
  "При обновлении пресета возникла ошибка, попробуйте еще раз":
    "There was an error updating the preset, please try again",
  "При удалении пресета возникла ошибка, попробуйте еще раз":
    "An error occurred while deleting the preset, please try again",
  "При включении пресета возникла ошибка, попробуйте еще раз":
    "An error occurred while enabling the preset, please try again",
  "При отключении пресета возникла ошибка, попробуйте еще раз":
    "An error occurred while disabling the preset, please try again",
  "Не удалось получить список пользователей, попробуйте еще раз": "Failed to get the list of users, try again",
  // - reports.ts
  "При загрузке списка отчетов возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the report list, please try again",
  "При загрузке отчета возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the report, please try again",
  "При обновлении отчета возникла ошибка, попробуйте еще раз":
    "An error occurred while updating the report, please try again",
  "При запуске отчета возникла ошибка, попробуйте еще раз":
    "An error occurred while starting the report, please try again",
  "При прекращении отчета возникла ошибка, попробуйте еще раз":
    "An error occurred while terminating the report, please try again",
  "При удалении отчета возникла ошибка, попробуйте еще раз":
    "An error occurred while deleting the report, please try again",
  "При восстановлении отчета возникла ошибка, попробуйте еще раз":
    "An error occurred while recreate the report, please try again",
  // - user.ts
  "При получении данных пользователя возникла ошибка, попробуйте еще раз":
    "An error occurred while getting user data, please try again",
  "При получении данных организации возникла ошибка, попробуйте еще раз":
    "There was an error getting your organization details, please try again",
  "При получении данных департамента возникла ошибка, попробуйте еще раз":
    "An error occurred while getting department data, please try again",
  "При обновлении пользователя возникла ошибка, попробуйте еще раз":
    "An error occurred while updating the user, please try again",
  "Ваша версия данных пользователя устарела, мы обновили данные, попробуйте еще раз":
    "Your version of user data is out of date, we have updated the data, please try again",
  // - list.ts
  "При загрузке списков возникла ошибка, попробуйте еще раз": "An error occurred while loading lists, please try again",
  "При создании списка возникла ошибка, попробуйте еще раз":
    "An error occurred while creating the list, please try again",
  "При загрузке списка возникла ошибка, попробуйте еще раз":
    "An error occurred while loading the list, please try again",
  "При удалении списка возникла ошибка, попробуйте еще раз":
    "An error occurred while deleting the list, please try again",
  "Не удалось обновить список возникла ошибка, попробуйте еще раз":
    "An error occurred while updating the list, please try again",
  "При загрузке абонентов возникла ошибка, попробуйте еще раз":
    "An error occurred while loading recipients, please try again",
  "Такое имя списка уже используется в вашей организации, выберите другое":
    "This list name is already in use in your organization, please select another",
  "Вы загрузили пустой файл": "Empty file uploaded",
  // - simple-message.ts
  "Ошибка отправки. Попробуйте еще раз или обратитесь в поддержку": "Dispatch Error. Try again or contact support",
  // - client.ts
  "При загрузке пользователей возникла ошибка, попробуйте еще раз":
    "An error occurred while loading users, please try again",
  "При создании пользователя возникла ошибка, попробуйте еще раз":
    "An error occurred while creating the user, please try again",
  "Такое имя пользователя уже используется в вашей организации, выберите другое":
    "This user name is already in use in your organization, please select another",
  "Не удалось создать учетную запись. Попробуйте еще раз": "Failed to create account. Try again",
  "При обновлении департамента возникла ошибка, попробуйте еще раз":
    "An error occurred while updating the department, please try again",
  "При создании департамента возникла ошибка, попробуйте еще раз":
    "An error occurred while creating the department, please try again",
  "Такое имя департамента уже используется в вашей организации, выберите другое":
    "This department name is already in use in your organization, please select another",
  "Не удалось сбросить пароль. Попробуйте еще раз.": "Failed to reset password. Try again."
};
