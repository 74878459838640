import notificationAnt from "antd/es/notification";
import { ArgsProps } from "antd/es/notification/interface";
import { clsx } from "clsx";
import { translation } from "../translation/translation";
import { ReactNode } from "react";

interface OwnProps extends ArgsProps {}

type Props = OwnProps;

let messages: string[] = [];

export const notification = (props: Props) => {
  const { key, message, description } = props;

  if (key) {
    if (messages.includes(key.toString())) {
      return;
    }

    messages.push(key.toString());
  }

  return notificationAnt.open({
    duration: 5,
    ...props,
    message: message ? <div className="e2e-notification-message">{translation(message.toString())}</div> : undefined,
    description: description ? (
      <div className="e2e-notification-description">{translation(description.toString())}</div>
    ) : undefined,
    onClose: () => {
      if (key) {
        messages = messages.filter((messageKey) => messageKey !== key);
      }
      props?.onClose?.();
    },
    className: clsx(props.className, "e2e-notification")
  });
};

export const notificationSuccess = (message: string, description?: string | ReactNode, key?: string) =>
  notification({ message, description, type: "success", key });

export const notificationDanger = (message: string, description?: string | ReactNode, key?: string) =>
  notification({ message, description, type: "error", key });

export const notificationWarning = (message: string, description?: string | ReactNode, key?: string) =>
  notification({ message, description, type: "warning", key });

export const notificationInfo = (message: string, description?: string | ReactNode, key?: string) =>
  notification({ message, description, type: "info", key });
