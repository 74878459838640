import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { getAuthQueryToken } from "./helpers/auth";
import { GrantType } from "projects/common/types/models/auth";
import environment from "projects/common/shared/config/environment";
import { Token } from "projects/common/types/interfaces/auth";
import { RootState } from "projects/common/app/store";
import { authActions } from "projects/common/entities/auth/model";

type BaseQueryParams = {};

export type ExtraOptions = { skipReAuth?: boolean };

export const getBaseQuery = (data: Partial<BaseQueryParams> = {}) =>
  fetchBaseQuery({
    baseUrl: environment.API_URL,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth?.data?.access_token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      if (environment.LANGUAGE) {
        headers.set("Accept-Language", environment.LANGUAGE);
      }

      return headers;
    }
  });

export const getBaseQueryWithReauth =
  (
    params: Partial<BaseQueryParams> = {}
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions> =>
  async (args, api, extraOptions: ExtraOptions) => {
    const baseQuery = getBaseQuery(params);
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401 && !extraOptions?.skipReAuth) {
      const state = api.getState() as RootState;
      const refreshToken = state.auth.data?.refresh_token || "";
      const logout = () => api.dispatch(authActions.clearAuth());
      const setToken = (data: Token) => api.dispatch(authActions.setToken(data));

      if (!refreshToken) {
        logout();
        return result;
      }

      const refreshResult = await baseQuery(
        getAuthQueryToken({
          clientId: state.auth.clientId,
          grantType: GrantType.RefreshToken,
          refreshToken
        }),
        api,
        extraOptions
      );

      if (refreshResult?.error || !refreshResult?.data) {
        logout();
        return result;
      }

      setToken(refreshResult?.data as Token);
      result = await baseQuery(args, api, extraOptions);

      if (result?.error?.status === 401) {
        logout();
        return result;
      }
    }
    return result;
  };

export const api = createApi({
  reducerPath: "api",
  baseQuery: getBaseQueryWithReauth(),
  tagTypes: [
    "Auth",
    "User",
    "Campaign",
    "CrmChannels",
    "CrmSenders",
    "Report",
    "AuditEvent",
    "AuditSection",
    "AuditActors",
    "MessageLookup",
    "SenderPattern",
    "SenderPatternCarriers",
    "SenderPatternSmsPattern",
    "SenderPatternSmsPatternState",
    "SenderPatternLegalEntity",
    "TrafficType",
    "Preset",
    "List",
    "ListRecipient",
    "AccessProfile",
    "Client",
    "ClientStatus",
    "ClientCredential",
    "ClientDepartment",
    "ClientUser"
  ],
  endpoints: () => ({})
});
