import React, { Fragment, useEffect, useState } from "react";
import { Location, Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "projects/common/app/hooks/useStore";
import { authActions, selectAuth } from "projects/common/entities/auth/model";
import { selectUser, userActions } from "projects/common/entities/user/model";
import { useLazyMeQuery } from "projects/common/shared/api/user";
import Box from "projects/common/shared/ui/typography/Box";
import errorHandler from "projects/common/shared/lib/errorHandler";
import Skeleton from "projects/common/shared/ui/skeleton/Skeleton";
import { useLazyUserConfigPropertyQuery } from "projects/common/shared/api/userConfig";

const AuthRoute: React.FC<any> = ({ children }) => {
  const location: Location = useLocation();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const [fetchUser] = useLazyMeQuery();
  const [fetchUserConfigProperty] = useLazyUserConfigPropertyQuery();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth?.access_token && !user) {
      const getUser = async () => {
        setLoading(true);
        const [meResult, propertyResult] = await Promise.allSettled([
          fetchUser().unwrap(),
          fetchUserConfigProperty().unwrap()
        ]);

        if (meResult.status === "fulfilled") {
          const credential = meResult.value;
          const property = propertyResult.status === "fulfilled" ? propertyResult.value || [] : [];

          dispatch(userActions.setUser({ credential, config: { property } }));
          setLoading(false);
        } else {
          const error = meResult.reason;
          setLoading(false);
          errorHandler(error, "При получении данных пользователя возникла ошибка, попробуйте еще раз");
          dispatch(authActions.clearAuth());
        }
      };

      getUser();
    } else {
      setLoading(false);
    }
  }, [user, auth]);

  const renderContent = () => {
    if (loading) {
      return (
        <Box m={40}>
          <Skeleton title paragraph={{ rows: 4 }} />
        </Box>
      );
    }

    return children;
  };

  return (
    <Fragment>
      {auth?.access_token ? renderContent() : <Navigate to={{ pathname: "/login" }} state={{ from: location }} />}
    </Fragment>
  );
};

export default AuthRoute;
