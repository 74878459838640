import * as yup from "yup";
import { validationTime } from "./validation";

yup.setLocale({
  object: {},
  mixed: {
    default: () => ({ key: "invalid" }),
    required: () => ({ key: "required" })
  },
  number: {
    min: ({ min }) => ({ key: "numberMin", values: { min } }),
    max: ({ max }) => ({ key: "numberMax", values: { max } }),
    integer: () => ({ key: "typeError", values: { type: "number" } }),
    positive: ({ value }) => ({ key: "positive", values: { value } })
  },
  string: {
    min: ({ min }) => ({ key: "stringMin", values: { min } }),
    max: ({ max }) => ({ key: "stringMax", values: { max } }),
    length: ({ length }) => ({ key: "stringLength", values: { length } }),
    email: ({ value }) => ({ key: "email", values: { value } }),
    matches: ({ value }) => ({ key: "matches", values: { value } })
  },
  array: {
    min: ({ min }) => ({ key: "arrayMin", values: { min } }),
    max: ({ max }) => ({ key: "arrayMax", values: { max } })
  }
});

yup.addMethod(yup.string, "time", function (msg: string) {
  return this.test({
    name: "time",
    exclusive: false,
    message: msg || "time",
    test: (value) => !value || validationTime(value)
  });
});

yup.addMethod(yup.object, "atLeastOneOf", function (list: string[]) {
  return this.test({
    name: "atLeastOneOf",
    message: `Выберите хотя бы один из вариантов: ${list.join(", ")}`,
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) => value == null || list.some((f) => value[f] != null)
  });
});

yup.addMethod(yup.object, "atLeastOneOfTrue", function (list: string[]) {
  return this.test({
    name: "atLeastOneOfTrue",
    message: `Выберите хотя бы один из вариантов: ${list.join(", ")}`,
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) => value == null || list.some((f) => value[f] === true || value[f] === "true")
  });
});

yup.addMethod(yup.tuple, "timeRangeInterval", function (interval?: number) {
  return this.test({
    name: "timeRangeInterval",
    message: `Минимальный интервал времени: ${interval || "1 мин"}`,
    exclusive: true,
    test: (value) => {
      if (value?.[0] && value?.[1]) {
        return value[0] !== value[1];
      }

      return true;
    }
  });
});
