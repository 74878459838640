/**
 * Sender-pattern public API
 * API для работы с именами и шаблонами
 *
 * The version of the OpenAPI document: 2.1
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Состояние регистрации
 */
export enum ClientRegistrationSenderState {
  APPROVAL = "APPROVAL",
  PLANNED_ACTIVE = "PLANNED_ACTIVE",
  REJECTED = "REJECTED",
  ACTIVE = "ACTIVE",
  PLANNED_DELETED = "PLANNED_DELETED",
  DELETED = "DELETED"
}
