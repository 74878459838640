/**
 * hub reports API
 * Фронтэнд сервиса отчетов для хаба
 *
 * The version of the OpenAPI document: 2.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PageRequestFilterValue } from "./pageRequestFilterValue";
import { Sort } from "../../enums/sort";

/**
 * Параметры для запросов с пагинацией
 */
export interface PageRequest {
  /**
   * Количество эелемнтов на странице
   */
  size: number;
  /**
   * Номер страницы начиная с 0
   */
  page: number;
  /**
   * Сортировка с возможностью указать несколько полей<br> Данные сортируются в том порядке, в котором указаны
   */
  sort?: { [key: string]: Sort };
  /**
   * Фильтр по полям
   */
  filter?: { [key: string]: PageRequestFilterValue };
}
export namespace PageRequest {
  export type SortEnum = "ASC" | "DESC";
  export const SortEnum = {
    ASC: "ASC" as SortEnum,
    DESC: "DESC" as SortEnum
  };
}
