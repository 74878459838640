import React, { PropsWithChildren } from "react";
import Fallback from "projects/common/shared/ui/fallback/Fallback";

const ListsBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'common-lists' */
      /* webpackPrefetch: 10 */
      "./ListsRouter"
    )
);

const ListsModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <ListsBundle />
  </React.Suspense>
);

export default ListsModule;
