/**
 * Pulibc simple message sender client area API
 * API для простой отправки сообщений
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Тип рассылки
 */
export enum Result {
  Failed = "FAILED",
  Success = "SUCCESS"
}
