import { Access } from "../../types/interfaces/access";
import { RouteItem } from "../../types/interfaces/route";
import { ROUTE_PATH } from "../../shared/config/routes";
import CampaignsModule from "../../pages/campaigns";
import ListsModule from "../../pages/lists";
import ReportsModule from "../../pages/reports";
import AuditModule from "../../pages/audit";
import UserModule from "../../pages/user";
import MessageLookupModule from "../../pages/message-lookup";
import NamesModule from "../../pages/names";
import PresetModule from "../../pages/preset";
import SimpleMessageModule from "../../pages/simple-message";
import ManagementModule from "../../pages/management";
import React from "react";
import SmsPatternsModule from "../../pages/sms-patterns";
import SmsPatternCheckModule from "../../pages/sms-pattern-check";

export const getRoutesDefault = (access: Access): RouteItem[] => [
  { id: "campaigns", path: ROUTE_PATH.campaigns, element: <CampaignsModule />, visible: !!access?.campaignVisible },
  { id: "lists", path: ROUTE_PATH.lists, element: <ListsModule />, visible: !!access?.listVisible },
  {
    id: "reports",
    path: ROUTE_PATH.reports,
    element: <ReportsModule />,
    visible: !!access?.reportVisible
  },
  { id: "audit", path: ROUTE_PATH.audit, element: <AuditModule />, visible: !!access?.auditVisible },
  { id: "user", path: ROUTE_PATH.user, element: <UserModule />, visible: true },
  {
    id: "message-lookup",
    path: ROUTE_PATH.activityLog,
    element: <MessageLookupModule />,
    visible: !!access?.messageLookupVisible
  },
  {
    id: "names",
    path: ROUTE_PATH.names,
    element: <NamesModule />,
    visible: !!access?.namesVisible
  },
  {
    id: "preset",
    path: ROUTE_PATH.preset,
    element: <PresetModule />,
    visible: !!access?.presetVisible
  },
  {
    id: "simple-message",
    path: ROUTE_PATH.simpleMessage,
    element: <SimpleMessageModule />,
    visible: !!access?.simpleMessageVisible
  },
  {
    id: "management",
    path: ROUTE_PATH.management,
    element: <ManagementModule />,
    visible: !!access?.managementVisible
  },
  {
    id: "sms-patterns",
    path: ROUTE_PATH.smsPatterns,
    element: <SmsPatternsModule />,
    visible: !!access?.smsPatternsVisible
  },
  {
    id: "sms-pattern-check",
    path: ROUTE_PATH.smsPatternCheck,
    element: <SmsPatternCheckModule />,
    visible: !!access?.smsPatternCheckVisible
  }
];

export const getPageDefault = (access: Access): string => {
  if (access?.campaignVisible) {
    return ROUTE_PATH.campaigns;
  } else if (access?.simpleMessageVisible) {
    return ROUTE_PATH.simpleMessage;
  } else if (access?.reportVisible) {
    return ROUTE_PATH.reports;
  } else if (access?.listVisible) {
    return ROUTE_PATH.lists;
  } else if (access?.messageLookupVisible) {
    return ROUTE_PATH.activityLog;
  } else if (access?.namesVisible) {
    return ROUTE_PATH.names;
  } else if (access?.auditVisible) {
    return ROUTE_PATH.audit;
  } else if (access?.presetVisible) {
    return ROUTE_PATH.preset;
  } else if (access?.managementVisible) {
    return ROUTE_PATH.management;
  } else if (access?.smsPatternsVisible) {
    return ROUTE_PATH.smsPatterns;
  } else if (access?.smsPatternCheckVisible) {
    return ROUTE_PATH.smsPatternCheck;
  } else {
    return ROUTE_PATH.user;
  }
};
