export const ERROR_MESSAGE = {
  FILE_GENERATE: "Не удалось запустить генерацию файла, пожалуйста попробуйте еще раз",
  FILE_DOWNLOAD: "Не удалось скачать файл, пожалуйста попробуйте еще раз",
  FILE_UPLOAD: "Не удалось загрузить файл, пожалуйста попробуйте еще раз",
  MAX_FILE_SIZE: "Размер файла превышает максимально возможный. Используйте другой файл.",
  UPDATE_ENTITY_INVALID_VERSION: "Ваша версия устарела, мы обновили данные, попробуйте еще раз"
};

export const ERROR_CODE = {
  invalid_ip: "Доступ с данного IP запрещен", // Ошибка авторизации из сервиса Auth
  internal_error: "Непредвиденная ошибка, пожалуйста попробуйте еще раз", // Непредвиденная ошибка, в message всегда будет общий текст “Непредвиденная ошибка“. Можно на message не смотреть.
  business_validation_error: null, // Ошибка валидации, бизнесовая ошибка выводится клиенту
  business_validation_error_with_details: null, // Ошибка валидации, бизнесовая ошибка выводится клиенту, поле message и в details подробное описание(пока нигде не используется, на будущее)
  pricelist_file_import_error: null, // Специализированая ошибка валидации. Используется только для ошибок загрузки файла прайслиста
  excel_limit_exceeded_export_error: null, // "Слишком большой объем файла". Специализированная ошибка в поиске сообщений. Используется, когда в запрошенной выгрузке строчек больше, чем влезает в эксель. Текста ошибки нет, можно ориентироваться на код ошибки.
  export_excel_row_limit_exceeded_error:
    "Количество выбранных записей превышает допустимый лимит количества строк excel", // "Слишком большой объем Excel файла". Специализированная ошибка в именах
  validation_error: null,
  not_found: null, // Не найден объект, можно выводить клиенту message
  access_denied: "Доступ запрещен",
  unauthorized: "У Вас закончилась сессия, пожалуйста, авторизуйтесь заново",
  conflict: "Ошибка контроля версий" // Ошибка контроля версий
};

export const ERROR_STATUS = {
  0: "Ошибка соединения. Проверьте Ваше подключение к интернету",
  401: "У Вас закончилась сессия, пожалуйста, авторизуйтесь заново",
  403: "Доступ запрещен",
  413: "Размер файла превышает максимально допустимый. Используйте другой файл."
};
