import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { Env } from "projects/common/types/enums/env";
import { api } from "projects/common/shared/api/api";
import environment from "projects/common/shared/config/environment";
import { appName, appReducer } from "../entities/app/model";
import { authName, authReducer } from "../entities/auth/model";
import { userName, userReducer } from "../entities/user/model";
import { reportName, reportReducer } from "../entities/report/model";
import { campaignName, campaignReducer } from "../entities/campaign/model";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [appName]: appReducer,
    [authName]: authReducer,
    [userName]: userReducer,
    [reportName]: reportReducer,
    [campaignName]: campaignReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware),
  devTools: environment.ENV !== Env.Prod
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
