import { Project } from "projects/common/types/enums/project";
import { Theme } from "projects/common/types/interfaces/styles";
import { ReportType, SourceTraffics } from "projects/common/types/enums/report";
import environment from "projects/common/shared/config/environment";
import { TrafficType } from "projects/common/types/enums/message";
import { ReportGroupName } from "projects/common/types/interfaces/report";
import { TRAFFIC_TYPE } from "../message-lookup/config";

// Styles
export const projectGetTheme = (project?: Project): Theme => {
  switch (project || environment.PROJECT) {
    case Project.MEGAFON:
      return "mg";
    case Project.SEVENTECH:
    case Project.SEVENTECH_BY:
    case Project.SEVENTECH_EN:
      return "st";
    case Project.GPBM:
      return "gb";
    case Project.WHITE_LABEL:
    case Project.BEELINE:
    default:
      return "default";
  }
};

export const projectGetLoaderName = () => {
  switch (projectGetTheme(environment.PROJECT)) {
    case "mg":
      return "loader";
    case "gb":
      return "loader-gb";
    default:
      return "spinner";
  }
};

// Report
export const projectCheckReportType = (type: ReportType): boolean => {
  switch (type) {
    case ReportType.FINANCIAL:
      return [Project.SEVENTECH_BY, Project.SEVENTECH_EN].includes(environment.PROJECT);
    default:
      return true;
  }
};
export const projectCheckSourceTraffic = (type: SourceTraffics): boolean => {
  switch (type) {
    case SourceTraffics.EMAIL:
      return [Project.MEGAFON, Project.WHITE_LABEL, Project.BEELINE].includes(environment.PROJECT);
    default:
      return true;
  }
};
export const projectCheckReportGroup = (group: ReportGroupName): boolean => {
  switch (group) {
    case "pattern":
      return environment.PROJECT !== Project.SEVENTECH_BY;
    default:
      return true;
  }
};

// Message
export const projectCheckTrafficType = (type: TrafficType): boolean => {
  const TRAFFIC_TYPE: { [key in TrafficType]: Project[] } = {
    [TrafficType.INTERNATIONAL]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.MULTINAMING]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.SERVICE]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.AUTHORIZATION]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.GLOBAL]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.TRANSACTIONAL]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.ADVERTISING]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.TEMPLATED_PROMOTION]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.INFORMATIONAL]: [Project.GPBM, Project.SEVENTECH, Project.SEVENTECH_EN],
    [TrafficType.IDENTIFICATIONAL]: [Project.GPBM, Project.SEVENTECH, Project.SEVENTECH_EN],
    [TrafficType.BUSINESS_INIT]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.SEVENTECH_BY,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.USER_INIT]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.SEVENTECH_BY
    ],
    [TrafficType.TRANSACTION]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.SEVENTECH_BY,
      Project.WHITE_LABEL,
      Project.BEELINE
    ],
    [TrafficType.PROMOTION]: [
      Project.MEGAFON,
      Project.GPBM,
      Project.SEVENTECH,
      Project.SEVENTECH_EN,
      Project.SEVENTECH_BY,
      Project.WHITE_LABEL,
      Project.BEELINE
    ]
  };

  return TRAFFIC_TYPE[type].includes(environment.PROJECT);
};

// Специальные названия для типов трафика по проектам
export const projectTitleTrafficType = (type: TrafficType): string => {
  const TITLE_TRAFFIC_TYPE: { [key in Project]?: { [kei in TrafficType]?: string } } = {
    [Project.MEGAFON]: {
      [TrafficType.ADVERTISING]: "Нешаблонированный"
    }
  };

  return TITLE_TRAFFIC_TYPE?.[environment.PROJECT]?.[type] ?? TRAFFIC_TYPE?.[type].title;
};
