export default {
  exportSourceRecipients: {
    data: {
      url: "https://int.seven.tech/dev-mgfninformer/api/reports/public/informer/v1/download/%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csvfc3d3c70-ee07-4091-b14e-244106ad3ef2?response-content-disposition=attachment%3B%20filename%20%3D%22%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csv%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211210T060143Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=W2JozDrB3cuSc5AJGTXp%2F20211210%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Signature=f935e2984d3bc5f56d821e209590fcbbe0963babb1dec226bf3dfaf86f647226"
    }
  },
  exportSourceErrorRecipients: {
    data: {
      url: "https://int.seven.tech/dev-mgfninformer/api/reports/public/informer/v1/download/%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csvfc3d3c70-ee07-4091-b14e-244106ad3ef2?response-content-disposition=attachment%3B%20filename%20%3D%22%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csv%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211210T060143Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=W2JozDrB3cuSc5AJGTXp%2F20211210%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Signature=f935e2984d3bc5f56d821e209590fcbbe0963babb1dec226bf3dfaf86f647226"
    }
  }
};
