import React from "react";
import Fallback from "projects/common/shared/ui/fallback/Fallback";

const SmsPatternsBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'common-sms-patterns' */
      /* webpackPrefetch: 10 */
      "./SmsPatternsRouter"
    )
);

const SmsPatternsModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <SmsPatternsBundle />
  </React.Suspense>
);

export default SmsPatternsModule;
