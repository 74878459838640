export const ROUTE_ID = {
  clientUserId: ":clientUserId",
  clientCredentialId: ":clientCredentialId"
};
export const ROUTE_PARAMS: typeof ROUTE_ID = {
  clientUserId: "clientUserId",
  clientCredentialId: "clientCredentialId"
} as const;

export const ROUTE_PATH = {
  campaigns: "campaigns",
  lists: "lists",
  reports: "reports",
  audit: "audit",
  journal: "journal",
  user: "user",
  activityLog: "activity-log",
  names: "names",
  preset: "preset",
  simpleMessage: "simple-message",
  management: "management", // Управление,
  smsPatterns: "sms-patterns", // Шаблоны
  smsPatternCheck: "sms-pattern-check" // Проверка шаблона
};
export const MANAGEMENT_ROUTE_PATH = {
  users: "users", // Пользователь
  departments: "departments" // Департамент
};
export const CLIENT_USER_ROUTE_PATH = {
  new: "new", // Добавление Пользователя
  credential: "credential" // Учетная запись
};
export const CLIENT_CREDENTIAL_ROUTE_PATH = {
  new: "new" // Добавление Учетной записи
};

export const navigateTo = {
  // Client User
  clientUsers: () => `/${ROUTE_PATH.management}/${MANAGEMENT_ROUTE_PATH.users}`,
  clientUserNew: () => `${navigateTo.clientUsers()}/${CLIENT_USER_ROUTE_PATH.new}`,
  // Client User -> Credential
  clientCredentialNew: (userId: string | number) =>
    `${navigateTo.clientUsers()}/${userId}/${CLIENT_USER_ROUTE_PATH.credential}/${CLIENT_CREDENTIAL_ROUTE_PATH.new}`,
  clientCredentialEdit: (userId: string | number, credentialId: string | number) =>
    `${navigateTo.clientUsers()}/${userId}/${CLIENT_USER_ROUTE_PATH.credential}/${credentialId}`,
  // Client Department
  clientDepartments: () => `/${ROUTE_PATH.management}/${MANAGEMENT_ROUTE_PATH.departments}`,

  // Sms Patterns
  smsPatterns: () => `/${ROUTE_PATH.smsPatterns}`,

  // Sms Pattern Check
  smsPatternCheck: () => `/${ROUTE_PATH.smsPatternCheck}`
};
