import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { ClassValue, clsx } from "clsx";

interface OwnProps {
  type?: "link" | "router";
  to?: string;
  href?: string;
  target?: string;
  onClick?: (props?: any) => any;
  active?: boolean;
  isActive?: () => any;
  className?: ClassValue;
  id?: string;
  icon?: boolean;
}

type Props = OwnProps;

const NavbarItem = (props: PropsWithChildren<Props>) => {
  const { id, className, to = "", type = "link", href, target, onClick, children, active, icon } = props;

  if (type === "link") {
    const Tag = href ? "a" : "div";
    const linkProps = href ? { href, target } : {};

    return (
      <Tag
        className={clsx("navbar__item", className, {
          navbar__item_active: active,
          navbar__item_icon: icon
        })}
        onClick={onClick}
        id={id}
        {...linkProps}
      >
        {children}
      </Tag>
    );
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        clsx("navbar__item", className, {
          navbar__item_active: isActive,
          navbar__item_icon: icon
        })
      }
      id={id}
    >
      {children}
    </NavLink>
  );
};

export default NavbarItem;
