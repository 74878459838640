export const TIME_FORMAT = "HH:mm";
export const TIME_SECONDS_FORMAT = `${TIME_FORMAT}:ss`;

export const SERVER_DATE_FORMAT = "yyyy-MM-dd";
export const SERVER_TIME_FORMAT = `${SERVER_DATE_FORMAT} ${TIME_FORMAT}:ss`;

export const FNS_DATE_FORMAT = "dd.MM.yyyy";
export const FNS_TIME_FORMAT = `${FNS_DATE_FORMAT} ${TIME_FORMAT}`;
export const FNS_TIME_SECONDS_FORMAT = `${FNS_DATE_FORMAT} ${TIME_SECONDS_FORMAT}`;

export const DAYJS_DATE_FORMAT = "DD.MM.YYYY";
export const DAYJS_TIME_FORMAT = `${DAYJS_DATE_FORMAT} ${TIME_FORMAT}`;

export const MONTHS_SHORT: { [key: number]: string } = {
  1: "янв",
  2: "фев",
  3: "мар",
  4: "апр",
  5: "май",
  6: "июнь",
  7: "июль",
  8: "авг",
  9: "сен",
  10: "окт",
  11: "ноя",
  12: "дек"
};
