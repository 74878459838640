/**
 * hub reports API
 * Фронтэнд сервиса отчетов для хаба
 *
 * The version of the OpenAPI document: 2.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PageRequestFilterValue {
  value?: string;
  rule?: PageRequestFilterValue.RuleEnum;
}
export namespace PageRequestFilterValue {
  export type RuleEnum = "STRICT" | "START_WITH" | "END_WITH" | "CONTAINS";
  export const RuleEnum = {
    Strict: "STRICT" as RuleEnum,
    StartWith: "START_WITH" as RuleEnum,
    EndWith: "END_WITH" as RuleEnum,
    Contains: "CONTAINS" as RuleEnum
  };
}
