import {
  Campaign,
  CampaignMetaDataState,
  Source,
  SourceState,
  SourceType
} from "projects/common/types/models/campaign";
import { CampaignState, CampaignStep, CampaignType, ScenarioItemType } from "projects/common/types/enums/campaign";
import { CampaignForm, CampaignFormName } from "projects/common/types/interfaces/campaign";
import { CAMPAIGN_FORMS } from "projects/common/entities/campaign/config";
import { isASCII, prepareFileName } from "projects/common/shared/lib/helpers";
import Trans from "projects/common/shared/ui/translation/Trans";
import { translation } from "projects/common/shared/ui/translation/translation";
import { ROUTE_PATH } from "projects/common/shared/config/routes";

/* Route */
export const checkCampaignRoute = (item: Campaign, step: CampaignStep): boolean => {
  if (item.type === CampaignType.INDIVIDUAL_FROM_FILE) {
    return step === CampaignStep.View;
  }

  switch (step) {
    case CampaignStep.Create:
      return [CampaignState.NEW, CampaignState.DRAFT].includes(item.state as CampaignState);
    case CampaignStep.View:
      return [
        CampaignState.CREATING,
        CampaignState.CREATED,
        CampaignState.ACTIVATING,
        CampaignState.ENRICH_TIMEZONE,
        CampaignState.REPLACE_SHORT_LINK,
        CampaignState.ACTIVE,
        CampaignState.PAUSED,
        CampaignState.COMPLETE,
        CampaignState.FINISHED,
        CampaignState.FAILED,
        CampaignState.DELETED
      ].includes(item.state as CampaignState);
    default:
      return false;
  }
};

export const getCampaignRoute = (item: Campaign, relative: boolean = true): string => {
  const redirectCreate = relative
    ? `./${CampaignStep.Create}`
    : `/${ROUTE_PATH.campaigns}/${item.id}/${CampaignStep.Create}`;
  const redirectView = relative ? `./${CampaignStep.View}` : `/${ROUTE_PATH.campaigns}/${item.id}/${CampaignStep.View}`;
  const redirectList = `/${ROUTE_PATH.campaigns}`;

  if (item.type === CampaignType.INDIVIDUAL_FROM_FILE) {
    return redirectView;
  }

  switch (true) {
    case [CampaignState.NEW, CampaignState.DRAFT].includes(item.state as CampaignState):
      return redirectCreate;
    case [
      CampaignState.CREATING,
      CampaignState.CREATED,
      CampaignState.ACTIVATING,
      CampaignState.ENRICH_TIMEZONE,
      CampaignState.REPLACE_SHORT_LINK,
      CampaignState.ACTIVE,
      CampaignState.PAUSED,
      CampaignState.COMPLETE,
      CampaignState.FINISHED,
      CampaignState.FAILED,
      CampaignState.DELETED
    ].includes(item.state as CampaignState):
      return redirectView;
    default:
      return redirectList;
  }
};

/* Form */
export const getCampaignForms = (campaign: Campaign): CampaignForm[] => {
  // Блок сокращения ссылок по фича флагу
  let forms: CampaignForm[] = CAMPAIGN_FORMS.filter((form) => form?.id !== "shortLink" || campaign.showShortLinkBlock);

  switch (campaign?.type) {
    case CampaignType.INDIVIDUAL: {
      forms = forms.filter((item: CampaignForm) => item?.id !== "message");
      break;
    }
    case CampaignType.TAGS: {
      // Перемещаем Получатели на 3ю позицию
      let recipient: CampaignForm | null = null;
      forms = forms.filter((item: CampaignForm) => {
        if (item?.id === "recipient") {
          recipient = item;
          return false;
        }

        return true;
      });
      if (recipient) {
        forms.splice(2, 0, recipient);
      }
      break;
    }
  }

  return forms.map((form) => ({ ...form, name: translation(form.name), title: translation(form.title) }));
};

export const getCampaignValidates = (campaign: Campaign): { [key in CampaignFormName]?: boolean } => {
  const validateChannel = (): boolean => {
    if (!campaign?.scenario?.every((item) => item?.sender)) {
      return false;
    }

    if (campaign?.type === CampaignType.INDIVIDUAL) {
      return !!campaign?.scenario?.length;
    } else {
      return !!(campaign?.scenario?.length && campaign?.defaultText);
    }
  };

  const validateRecipient = (): boolean => {
    return !!(
      campaign?.sourceList?.length &&
      campaign.sourceList.every(
        (source: Source) =>
          source?.state &&
          ![SourceState.ERROR, SourceState.CREATING, SourceState.CREATED_WITH_ERROR].includes(source.state)
      ) &&
      campaign?.metaData?.state === CampaignMetaDataState.DONE &&
      (campaign?.metaData?.recipientsCount || 0) > 0
    );
  };

  const newValidates: { [key in CampaignFormName]?: boolean } = {};

  newValidates["name"] = !!campaign?.name;
  newValidates["date"] = !!campaign?.timetable?.days?.length;
  newValidates["message"] = !!(campaign?.type === CampaignType.INDIVIDUAL || campaign?.defaultText);
  newValidates["channel"] = validateChannel();
  newValidates["recipient"] = validateRecipient();
  newValidates["speed"] = true;
  newValidates["shortLink"] = true;
  newValidates["common"] = Object.values(newValidates)?.every((valid) => valid);

  return newValidates;
};

/* Type */
export const isCampaignText = (type?: CampaignType) => {
  switch (type) {
    case CampaignType.INDIVIDUAL:
      return false;
    case CampaignType.SIMPLE:
    case CampaignType.TAGS:
    default:
      return true;
  }
};

/* Source */
export const getSourceCaption = (sourceType: SourceType, description: any, stop: boolean = false): string => {
  const t = translation;
  switch (sourceType) {
    case SourceType.LIST:
      return stop ? `${t("Стоп-лист")}: "${description || ""}"` : `${t("Список")}: "${description || ""}"`;
    case SourceType.FILE:
      return stop ? `${t("Стоп-лист")} ${description || ""}` : `${t("Файл")} ${description || ""}`;
    default:
      return stop ? t("Стоп-лист ручного ввода") : t("Ручной ввод");
  }
};

export const getSourceFileName = (source: Source): string => {
  let type = SourceType.REQUEST;

  if (!!source?.type) {
    type = source.type;
  }

  return `${prepareFileName(getSourceCaption(type, source?.name, source?.stop))}.csv`;
};

export const getSourceFile = (sources?: Source[]): Source | null => {
  if (!sources) {
    return null;
  }

  return sources?.find((source) => source?.type === SourceType.FILE && source?.stop === false) || null;
};

export const getSourceTags = (sources?: Source[]): string[] => {
  if (!sources) {
    return [];
  }

  return getSourceFile(sources)?.tags || [];
};

/* Scenario */
export const getScenarioTtlText = (ttl?: number | null, type?: ScenarioItemType) => {
  if (type === ScenarioItemType.WHATSAPP) {
    return <Trans>Максимальное время жизни сообщения</Trans>;
  }

  if (!ttl) {
    return;
  }

  return (
    <>
      <Trans>Время жизни</Trans> <span className="e2e-scenario-ttl">{Math.round(ttl / 60)}</span> <Trans>минут</Trans>
    </>
  );
};

export const getMessageCount = (text?: string | null) => {
  let length = text?.length;

  if (!text || !length) {
    return 0;
  }

  const ASCII = isASCII(text);
  if (!ASCII) {
    length = length * 2;
  }
  const minLength = ASCII ? 160 : 140;
  const chankLength = ASCII ? 154 : 134;

  if (length <= minLength) {
    return 1;
  }

  return Math.ceil(length / chankLength);
};
