import { Client, Credential, Department, User } from "projects/common/types/models/user";
import { RoleName } from "projects/common/types/enums/roleName";

export const getUserMock = ({ id = "123e4567-e89b-12d3-a456-4266554400012", ...data }: Partial<User> = {}): User => ({
  id,
  name: "username",
  client: getClientMock(),
  department: getDepartmentMock(),
  ...data
});

export const getCredentialMock = ({
  id = "123e4567-e89b-12d3-a456-4266554400012",
  login = "Login",
  ...data
}: Partial<Credential> = {}): Credential => ({
  id,
  login,
  roles: [RoleName.TenantAdmin],
  user: getUserMock(),
  ...data
});

export const getClientMock = ({
  id = "123e4567-e89b-12d3-a456-4266554400012",
  ...data
}: Partial<Client> = {}): Client => ({
  id,
  name: "Название тенанта",
  ...data
});

export const getDepartmentMock = ({
  id = "123e4567-e89b-12d3-a456-4266554400012",
  ...data
}: Partial<Department> = {}): Department => ({
  id,
  name: "Название подразделения",
  client: getClientMock(),
  ...data
});

export default {
  credential: {
    data: getCredentialMock()
  },
  user: {
    data: getUserMock()
  },
  client: {
    data: getClientMock()
  },
  department: {
    data: getDepartmentMock()
  },
  clients: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getClientMock({ id: "1" }),
        getClientMock({ id: "2" }),
        getClientMock({ id: "3" }),
        getClientMock({ id: "4" }),
        getClientMock({ id: "5" }),
        getClientMock({ id: "6" }),
        getClientMock({ id: "7" }),
        getClientMock({ id: "8" }),
        getClientMock({ id: "9" }),
        getClientMock({ id: "10" })
      ]
    }
  },
  departments: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getDepartmentMock({ id: "1" }),
        getDepartmentMock({ id: "2" }),
        getDepartmentMock({ id: "3" }),
        getDepartmentMock({ id: "4" }),
        getDepartmentMock({ id: "5" }),
        getDepartmentMock({ id: "6" }),
        getDepartmentMock({ id: "7" }),
        getDepartmentMock({ id: "8" }),
        getDepartmentMock({ id: "9" }),
        getDepartmentMock({ id: "10" })
      ]
    }
  },
  credentials: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getCredentialMock({ id: "1" }),
        getCredentialMock({ id: "2" }),
        getCredentialMock({ id: "3" }),
        getCredentialMock({ id: "4" }),
        getCredentialMock({ id: "5" }),
        getCredentialMock({ id: "6" }),
        getCredentialMock({ id: "7" }),
        getCredentialMock({ id: "8" }),
        getCredentialMock({ id: "9" }),
        getCredentialMock({ id: "10" })
      ]
    }
  }
};
