/**
 * Campaign API
 * API для рассылок
 *
 * The version of the OpenAPI document: 1.12
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Статус мета инфмормации о рассылке
 */
export type CampaignMetaDataState = "RECALCULATE" | "DONE";

export const CampaignMetaDataState = {
  RECALCULATE: "RECALCULATE" as CampaignMetaDataState,
  DONE: "DONE" as CampaignMetaDataState
};
