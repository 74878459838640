export enum CampaignState {
  NEW = "NEW",
  DRAFT = "DRAFT",
  ENRICH_TIMEZONE = "ENRICH_TIMEZONE",
  REPLACE_SHORT_LINK = "REPLACE_SHORT_LINK",
  CREATING = "CREATING",
  CREATED = "CREATED",
  ACTIVATING = "ACTIVATING",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  COMPLETE = "COMPLETE",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  DELETED = "DELETED"
}

export enum CampaignStep {
  Create = "create",
  View = "view"
}

export enum CampaignType {
  SIMPLE = "SIMPLE",
  INDIVIDUAL = "INDIVIDUAL",
  INDIVIDUAL_FROM_FILE = "INDIVIDUAL_FROM_FILE",
  TAGS = "TAGS"
}

export enum ScenarioItemType {
  SMS = "sms",
  VIBER = "viber",
  VK = "vk",
  OK = "ok",
  VKOK = "vkok",
  WHATSAPP = "whatsapp",
  PUSH = "push"
}
