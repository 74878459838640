import React from "react";
import Fallback from "projects/common/shared/ui/fallback/Fallback";

const UserBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'common-user' */
      /* webpackPrefetch: 10 */
      "./User"
    )
);

const UserModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <UserBundle />
  </React.Suspense>
);

export default UserModule;
