import { api } from "./api";
import { PageRequest } from "projects/common/types/interfaces/pageResponse";
import {
  CreatePresetRequest,
  Preset,
  PresetPage,
  PresetPageWrapper,
  PresetWrapper
} from "projects/common/types/models/preset";
import { PresetFiltersType, PresetSortsType } from "projects/common/types/interfaces/preset";

const prefix = "/message-preset/public/hub/v1";
export const presetApiUrl = {
  presets: `${prefix}/preset/all`,
  presetCreate: `${prefix}/preset`,
  preset: (id: string) => `${prefix}/preset/${id}`,
  presetUpdate: (id: string) => `${prefix}/preset/${id}`,
  presetDelete: (id: string) => `${prefix}/preset/${id}`,
  presetEnable: (id: string) => `${prefix}/preset/${id}/enable`,
  presetDisable: (id: string) => `${prefix}/preset/${id}/disable`
};

export const presetApi = api.injectEndpoints({
  endpoints: (build) => ({
    presets: build.query<PresetPage, PageRequest>({
      query: (
        { page = 0, size = 10, ...other }: PageRequest<PresetFiltersType, PresetSortsType> = { page: 0, size: 10 }
      ) => ({
        url: presetApiUrl.presets,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: PresetPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(
          ({ id }) =>
            ({
              type: "Preset",
              id
            } as const)
        ) || []),
        { type: "Preset", id: "LIST" }
      ]
    }),
    presetCreate: build.mutation<Preset, CreatePresetRequest>({
      query: (queryArg) => ({
        url: presetApiUrl.presetCreate,
        method: "POST",
        body: queryArg
      }),
      transformResponse: (response: PresetWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, _req) => [
        { type: "Preset", id: _result?.id },
        { type: "Preset", id: "LIST" }
      ]
    }),
    preset: build.query<Preset, string>({
      query: (id) => ({
        url: presetApiUrl.preset(id)
      }),
      transformResponse: (response: PresetWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "Preset", id }]
    }),
    presetUpdate: build.mutation<Preset, Preset>({
      query: (queryArg) => ({
        url: presetApiUrl.presetUpdate(queryArg.id),
        method: "PUT",
        body: queryArg
      }),
      transformResponse: (response: PresetWrapper, meta, arg) => response.data,
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: preset } = await queryFulfilled;
          dispatch(api.util.updateQueryData("preset" as never, id as never, (draft) => preset));
        } catch (error: any) {
          if (error?.error?.status === 409) {
            dispatch(api.util.invalidateTags([{ type: "Preset", id }]));
          }
        }
      },
      invalidatesTags: (_result, _err, id) => [{ type: "Preset", id: "LIST" }]
    }),
    presetDelete: build.mutation<any, string>({
      query: (id) => ({
        url: presetApiUrl.presetDelete(id),
        method: "DELETE"
      }),
      invalidatesTags: (_result, _err, _req) => [{ type: "Preset", id: _result?.id }]
    }),
    presetEnable: build.mutation<Preset, string>({
      query: (id) => ({
        url: presetApiUrl.presetEnable(id),
        method: "PUT"
      }),
      transformResponse: (response: PresetWrapper, meta, arg) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: preset } = await queryFulfilled;
          dispatch(api.util.updateQueryData("preset" as never, id as never, (draft) => preset));
        } catch {}
      },
      invalidatesTags: (_result, _err, id) => [{ type: "Preset", id: "LIST" }]
    }),
    presetDisable: build.mutation<Preset, string>({
      query: (id) => ({
        url: presetApiUrl.presetDisable(id),
        method: "PUT"
      }),
      transformResponse: (response: PresetWrapper, meta, arg) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: preset } = await queryFulfilled;
          dispatch(api.util.updateQueryData("preset" as never, id as never, (draft) => preset));
        } catch {}
      },
      invalidatesTags: (_result, _err, id) => [{ type: "Preset", id: "LIST" }]
    })
  }),
  overrideExisting: false
});

export const {
  usePresetsQuery,
  usePresetQuery,
  useLazyPresetQuery,
  usePresetCreateMutation,
  usePresetUpdateMutation,
  usePresetDeleteMutation,
  usePresetEnableMutation,
  usePresetDisableMutation
} = presetApi;
