import { api } from "./api";
import { PageRequest } from "projects/common/types/interfaces/pageResponse";
import {
  CarriersFiltersType,
  LegalEntitiesFiltersType,
  LegalEntitiesSortsType,
  NamesFiltersType,
  NamesSortsType,
  SmsPatternFiltersType,
  SmsPatternSortsType
} from "projects/common/types/interfaces/senderPattern";
import {
  CarrierPage,
  CarrierPageWrapper,
  ClientRegistrationSender,
  ClientRegistrationSenderPage,
  ClientRegistrationSenderPageWrapper,
  ClientRegistrationSenderWrapper,
  LegalEntityPage,
  LegalEntityPageWrapper,
  PatternPage,
  PatternPageWrapper,
  PatternState,
  PatternStateWrapper
} from "projects/common/types/models/sender-pattern";

// API для работы с именами и шаблонами
const prefix = `/sender-pattern/client-area/v1`;
export const senderPatternApiUrl = {
  senders: `${prefix}/client-registration-sender/all`,
  sender: (id: string) => `${prefix}/client-registration-sender/${id}`,
  sendersExport: `${prefix}/client-registration-sender/export`,
  carriers: `${prefix}/carrier/all`,
  trafficType: `${prefix}/traffic-type/all`,
  smsPatterns: `${prefix}/sms-pattern/all`,
  smsPatternsExport: `${prefix}/sms-pattern/export`,
  smsPatternState: `${prefix}/sms-pattern/state`,
  legalEntities: `${prefix}/legal-entity/all`
};

export const senderPatternApi = api.injectEndpoints({
  endpoints: (build) => ({
    senderPatternSenders: build.query<ClientRegistrationSenderPage, PageRequest<NamesFiltersType, NamesSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: senderPatternApiUrl.senders,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: ClientRegistrationSenderPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ id }) => ({ type: "SenderPattern", id } as const)) || []),
        { type: "SenderPattern", id: "LIST" }
      ]
    }),
    senderPatternSender: build.query<ClientRegistrationSender, string>({
      query: (id) => ({
        url: senderPatternApiUrl.sender(id)
      }),
      transformResponse: (response: ClientRegistrationSenderWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "SenderPattern", id }]
    }),
    senderPatternSendersDownload: build.mutation<Blob, PageRequest<NamesFiltersType, NamesSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: senderPatternApiUrl.sendersExport,
        method: "POST",
        body: { page, size, ...other },
        responseHandler: async (response) => await (response?.status === 200 ? response.blob() : response.json()),
        cache: "no-cache"
      })
    }),
    senderPatternCarriers: build.query<CarrierPage, PageRequest<CarriersFiltersType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: senderPatternApiUrl.carriers,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: CarrierPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ id }) => ({ type: "SenderPatternCarriers", id } as const)) || []),
        { type: "SenderPatternCarriers", id: "LIST" }
      ]
    }),
    senderPatternSmsPatterns: build.query<PatternPage, PageRequest<SmsPatternFiltersType, SmsPatternSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: senderPatternApiUrl.smsPatterns,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: PatternPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ id }) => ({ type: "SenderPatternSmsPattern", id } as const)) || []),
        { type: "SenderPatternSmsPattern", id: "LIST" }
      ]
    }),
    senderPatternSmsPatternExport: build.mutation<Blob, PageRequest<SmsPatternFiltersType, SmsPatternSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: senderPatternApiUrl.smsPatternsExport,
        method: "POST",
        body: { page, size, ...other },
        responseHandler: async (response) => await (response?.status === 200 ? response.blob() : response.json()),
        cache: "no-cache"
      })
    }),
    senderPatternSmsPatternState: build.query<PatternState[], void>({
      query: (id) => ({
        url: senderPatternApiUrl.smsPatternState
      }),
      transformResponse: (response: PatternStateWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [{ type: "SenderPatternSmsPatternState", id: "LIST" }]
    }),
    senderPatternLegalEntities: build.query<
      LegalEntityPage,
      PageRequest<LegalEntitiesFiltersType, LegalEntitiesSortsType>
    >({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: senderPatternApiUrl.legalEntities,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: LegalEntityPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ id }) => ({ type: "SenderPatternCarriers", id } as const)) || []),
        { type: "SenderPatternLegalEntity", id: "LIST" }
      ]
    })
  }),
  overrideExisting: false
});

export const {
  useSenderPatternSendersQuery,
  useLazySenderPatternSendersQuery,
  useSenderPatternSenderQuery,
  useSenderPatternSendersDownloadMutation,
  useLazySenderPatternCarriersQuery,
  useSenderPatternSmsPatternsQuery,
  useSenderPatternSmsPatternStateQuery,
  useSenderPatternSmsPatternExportMutation,
  useLazySenderPatternLegalEntitiesQuery
} = senderPatternApi;
