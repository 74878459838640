import { MessageState, ReportType, SourceTraffics } from "projects/common/types/enums/report";
import { ScenarioItemType } from "projects/common/types/enums/campaign";
import { Report, ReportState } from "projects/common/types/models/reports";
import { REPORT_ADDITIONAL_GROUP_KEY, REPORT_GROUP_DEFAULT_ID } from "../../entities/report/config";

export const getReportMock = (data: Partial<Report> = {}): Report => ({
  id: "123e4567-e89b-12d3-a456-426655440000",
  state: ReportState.NEW,
  type: ReportType.SUMMARY,
  from: "2020-10-19",
  to: "2020-10-24",
  createdAt: "2020-10-04T15:04:24.045Z",
  startedAt: "2020-10-04T15:34:24.045Z",
  updatedAt: "2020-10-04T16:04:24.045Z",
  completedAt: "2020-10-04T16:04:24.045Z",
  channels: [ScenarioItemType.SMS],
  states: [MessageState.SENT],
  campaigns: [
    { id: "123e4567-e89b-12d3-a456-426655440000", name: "Рассылка 1" },
    { id: "123e4567-e89b-12d3-a456-426655440000", name: "Рассылка 2" },
    { id: "123e4567-e89b-12d3-a456-426655440000", name: "Рассылка 3" }
  ],
  trafficSources: [SourceTraffics.HTTP, SourceTraffics.SMPP, SourceTraffics.WEB],
  version: 0,
  metaDataFile: {
    name: "string",
    size: 2000000
  },
  owner: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Петр",
    department: {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      name: "Петр"
    },
    client: {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      name: "Петр"
    }
  },
  departmentIds: ["d70347df-57fb-45aa-ae31-4ffdc69ddd7e", "c86b7e99-4d39-45b9-bc9a-b81a01962712"],
  additionalFieldMap: {
    [REPORT_ADDITIONAL_GROUP_KEY]: REPORT_GROUP_DEFAULT_ID.join(",")
  },
  ...data
});

export default {
  reports: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getReportMock({ id: "1" }),
        getReportMock({ id: "2", type: ReportType.TRAFFIC }),
        getReportMock({ id: "3" }),
        getReportMock({ id: "4", type: ReportType.SUMMARY }),
        getReportMock({ id: "5", type: ReportType.SHORT_URL }),
        getReportMock({ id: "6", type: ReportType.TRAFFIC }),
        getReportMock({ id: "7", state: ReportState.COMPLETE, type: ReportType.TRAFFIC }),
        getReportMock({ id: "8" }),
        getReportMock({ id: "9", state: ReportState.COMPLETE }),
        getReportMock({ id: "10", state: ReportState.COMPLETE, type: ReportType.SUMMARY })
      ]
    }
  },
  report: { data: getReportMock({ id: "1", state: ReportState.DRAFT }) },
  reportTraffic: { data: getReportMock({ id: "2", state: ReportState.DRAFT, type: ReportType.TRAFFIC }) },
  reportSummary: { data: getReportMock({ id: "3", state: ReportState.DRAFT, type: ReportType.SUMMARY }) },
  reportShort: { data: getReportMock({ id: "4", state: ReportState.DRAFT, type: ReportType.SHORT_URL }) },
  reportDownloadUrl: {
    data: {
      url: "https://int.seven.tech/dev-mgfninformer/api/reports/public/informer/v1/download/%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csvfc3d3c70-ee07-4091-b14e-244106ad3ef2?response-content-disposition=attachment%3B%20filename%20%3D%22%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csv%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211210T060143Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=W2JozDrB3cuSc5AJGTXp%2F20211210%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Signature=f935e2984d3bc5f56d821e209590fcbbe0963babb1dec226bf3dfaf86f647226"
    }
  }
};
