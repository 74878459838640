/**
 * hub reports API
 * Фронтэнд сервиса отчетов для хаба
 *
 * The version of the OpenAPI document: 2.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Статус отчета
 */
export type ReportState = "NEW" | "DRAFT" | "CREATING" | "COMPLETE" | "FAILED" | "DELETED" | "CANCEL" | "ARCHIVED";

export const ReportState = {
  NEW: "NEW" as ReportState,
  DRAFT: "DRAFT" as ReportState,
  CREATING: "CREATING" as ReportState,
  COMPLETE: "COMPLETE" as ReportState,
  FAILED: "FAILED" as ReportState,
  DELETED: "DELETED" as ReportState,
  CANCEL: "CANCEL" as ReportState,
  ARCHIVED: "ARCHIVED" as ReportState
};
