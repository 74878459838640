import React, { PropsWithChildren } from "react";
import { ClassValue, clsx } from "clsx";
import { Typography } from "projects/common/types/interfaces/typography";
import { getTypographyClassNames } from "projects/common/shared/lib/classNames";

interface OwnProps extends Partial<Typography> {
  className?: ClassValue;
  onClick?: (event?: any) => any;
  onDoubleClick?: (event?: any) => any;
  maxWidth?: string | number;
  minWidth?: string | number;
  maxHeight?: string | number;
  width?: string | number;
  height?: string | number;
  id?: string;
}

export type BoxProps = OwnProps;

const Box = (props: PropsWithChildren<BoxProps>) => {
  const {
    className,
    height,
    width,
    maxWidth,
    minWidth,
    maxHeight,
    onClick,
    onDoubleClick,
    children,
    id,
    ...typographyProps
  } = props;

  return (
    <div
      style={{ height, width, maxWidth, minWidth, maxHeight }}
      className={clsx(className, getTypographyClassNames(typographyProps))}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      id={id}
    >
      {children}
    </div>
  );
};

export default Box;
