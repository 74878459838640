import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "projects/common/types/enums/loading";
import { RootState } from "../../app/store";

// Types
export interface CampaignState {
  dataIsEdited?: boolean;
  loading: Loading;
}

const initialState: CampaignState = {
  dataIsEdited: false,
  loading: Loading.Idle
};

// Reducer
export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setDataIsEdited(state, { payload }: PayloadAction<boolean>) {
      state.dataIsEdited = payload || undefined;
    },
    clear(state) {
      return initialState;
    }
  }
});
export const campaignActions = campaignSlice.actions;
export const campaignReducer = campaignSlice.reducer;
export const campaignName = campaignSlice.name;

// Selectors
export const selectCampaignState = (state: RootState) => state.campaign;
export const selectCampaignDataIsEdited = createSelector(
  selectCampaignState,
  (client: CampaignState) => !!client.dataIsEdited
);
