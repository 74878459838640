import environment from "projects/common/shared/config/environment";
import { Language } from "projects/common/types/enums/language";
import { TRANSLATION_RESOURCES } from "./translationResources";
import { ReactElement, ReactNode } from "react";

export type Translation = Record<keyof typeof TRANSLATION_RESOURCES, string | ReactNode>;

export const translation = <T extends any>(message?: T, i18nKey?: keyof Translation): T | string => {
  const language = environment.LANGUAGE;

  if (!message || language === Language.Russian) {
    return message || "";
  }

  return (
    (i18nKey && TRANSLATION_RESOURCES[i18nKey]) ||
    TRANSLATION_RESOURCES?.[(message || "") as keyof Translation] ||
    message ||
    ""
  );
};
