import { api } from "./api";
import { getAuthQueryLogout, getAuthQueryToken } from "./helpers/auth";
import { Token } from "projects/common/types/interfaces/auth";
import { Body, Credentials } from "projects/common/types/models/auth";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    token: build.mutation<Token, Credentials>({
      query: getAuthQueryToken,
      extraOptions: { skipReAuth: true },
      invalidatesTags: ["Auth"]
    }),
    logout: build.mutation<void, Body>({
      query: getAuthQueryLogout,
      extraOptions: { skipReAuth: true },
      invalidatesTags: ["Auth"]
    })
  })
});

export const { useTokenMutation, useLogoutMutation } = authApi;
