/**
 * Campaign API
 * API для рассылок
 *
 * The version of the OpenAPI document: 1.12
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Статус источника для рассылки
 */
export type SourceState = "CREATING" | "CREATED" | "DELETED" | "ERROR" | "CREATED_WITH_ERROR";

export const SourceState = {
  CREATING: "CREATING" as SourceState,
  CREATED: "CREATED" as SourceState,
  DELETED: "DELETED" as SourceState,
  ERROR: "ERROR" as SourceState,
  CREATED_WITH_ERROR: "CREATED_WITH_ERROR" as SourceState
};
