import React, { PropsWithChildren } from "react";
import styles from "./Panel.module.scss";
import { getTypographyClassNames } from "projects/common/shared/lib/classNames";
import { Typography } from "projects/common/types/interfaces/typography";
import { ClassValue, clsx } from "clsx";

interface OwnProps extends Partial<Typography> {
  className?: ClassValue;
  id?: string;
  shadow?: boolean;
}

type Props = OwnProps;

const Panel = (props: PropsWithChildren<Props>) => {
  const { className, id, children, shadow, ...typography } = props;

  return (
    <div
      id={id}
      className={clsx(styles.panel, { [styles.panel_shadow]: shadow }, className, getTypographyClassNames(typography))}
    >
      {children}
    </div>
  );
};

export default Panel;
