import { Preset, PresetState } from "projects/common/types/models/preset";

export const getPresetMock = (
  id: string = "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  { ...data }: Partial<Preset> = {}
): Preset => ({
  id,
  ownerLogin: "string",
  name: "Название",
  text: "4000 бонусов по акции на счете!",
  description: "Зачисление на счет",
  state: PresetState.ACTIVE,
  createdAt: "2022-07-02T06:24:59.970Z",
  updatedAt: "2022-07-02T06:24:59.970Z",
  ...data
});

export default {
  presets: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getPresetMock("1"),
        getPresetMock("2", { state: PresetState.DELETED }),
        getPresetMock("3"),
        getPresetMock("4", { state: PresetState.DISABLED }),
        getPresetMock("5"),
        getPresetMock("6"),
        getPresetMock("7", {
          text: "Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки Очень длинный текст для проверки "
        }),
        getPresetMock("8"),
        getPresetMock("9", { state: PresetState.DISABLED }),
        getPresetMock("10")
      ]
    }
  },
  preset: { data: getPresetMock("1") },
  presetDisable: { data: getPresetMock("1", { state: PresetState.DISABLED, name: "Деактивированный отче" }) }
};
