export const isTrue = (val: string | boolean | undefined): boolean => val === "true" || val === true;
export const isFalse = (val: string | boolean | undefined): boolean => val === "false" || val === false;

export const isASCII = (str: string) => /^[\x00-\x7F]*$/.test(str);
export const isLatin = (str: string) => /([A-Z])/gi.test(str);
export const isCyrillic = (str: string) => /([А-Я])/gi.test(str);
export const isSpace = (str: string) => /^[ ]*$/.test(str);
export const isFigureSpace = (str: string) => /^[  ⁠]*$/.test(str);
export const isTab = (str: string) => /^[\t]*$/.test(str);

export const getRandomInt = (min: number = 1, max: number = 100000) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getPercent = (total: number, count: number = 0) => (total ? Math.round((count / total) * 100) : 0);

export const arrayRange = <T extends object>(number: number, defaultItem?: any): T[] => {
  return Array.from(Array(number).keys()).map((item) => defaultItem || item);
};

export const sortByField = <T extends { [key: string]: any }>(arr: T[], field: string): T[] =>
  [...arr].sort((a, b) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));

export const numberWithSpaces = (x: number) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

export const prepareNumber = (str: string): number | undefined => {
  const num = str?.match(/\d+/g);

  return num ? +num?.join("") : undefined;
};

export const phoneFormatter = (phone: string, format = "+* *** ***-**-**", defaultValue?: string): string => {
  if (phoneClear(phone)?.length !== 11) {
    return defaultValue !== undefined ? defaultValue : phone;
  }

  let result = format;
  let numbers = "" + prepareNumber(phone);

  while (numbers && numbers.length > 0) {
    const replaceChar = numbers[0];

    numbers = numbers.substr(1);
    result = result.replace("*", replaceChar);
  }

  return result;
};

export const phoneClear = (phone: string, prefix = ""): string => {
  if (!phone) {
    return phone;
  }

  return prefix + (phone.match(/\d+/g)?.join("") || "");
};

export const phonePrepareSearch = (phone?: string | null): string | undefined => {
  if (!phone) {
    return undefined;
  }

  let msisdn = phone ? phoneClear(phone) : undefined;

  if (!msisdn || String(msisdn).length < 4) {
    return undefined;
  }

  if (String(msisdn).length === 11) {
    const clearPhone = String(msisdn).match(/8(\d{10})/);
    msisdn = clearPhone?.[1] ? `7${clearPhone[1]}` : msisdn;
  }

  return msisdn;
};

export const phonesFromText = (text: string, clear = true): string[] => {
  const arr = text?.replace(/\r\n/g, "\n").split("\n");

  return clear ? arr.filter((val) => phoneClear(val)?.length === 11) : arr;
};

export const declOfNum = (number: number, titles: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

export const isUrlValid = (url: string, regexp?: RegExp) => {
  const res = url.match(
    regexp || /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

  return !(res === null);
};

export const prepareFileName = (name: string) => {
  return name.replace(new RegExp("[ .]", "g"), "_");
};

export const getSizeKb = (bytes: number) => {
  return (bytes / 1024).toFixed(2);
};

export const getSizeMb = (bytes: number) => {
  return (bytes / 1024 / 1024).toFixed(2);
};

export const getSize = (bytes: number) => {
  if (1024 * 103 < bytes) {
    return `${getSizeMb(bytes)} МБ`;
  } else if (10 < bytes) {
    return `${getSizeKb(bytes)} КБ`;
  } else {
    return `${bytes} Б`;
  }
};

export const ucFirst = (str: string) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

export const getValue = (object: any, path: string) => {
  return path
    .replace(/\[/g, ".")
    .replace(/\]/g, "")
    .split(".")
    .reduce((o, k) => (o || {})[k], object);
};

export const omit = (obj: { [key: string]: any }, keys: string[]): { [key: string]: any } =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));

export const debounce = (callback: (...args: any[]) => void, wait: number = 500) => {
  let timeoutId: any = null;

  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export const memoize = <T extends Array<any> = Array<any>, F extends any = any>(fn: (...args: T) => F, json = true) => {
  const cache = new Map();

  return (...args: T): F => {
    const params = json ? JSON.stringify([...args]) : [...args];

    if (cache.has(params)) {
      // console.log("cached");
      return cache.get(params);
    } else {
      let result = fn(...args);
      cache.set(params, result);
      // console.log("not cached");
      return result;
    }
  };
};

export const isTextLarge = (element?: HTMLElement | null) => {
  if (!element) {
    return false;
  }

  return element.offsetWidth < element.scrollWidth;
};

export const checkObjectValues = (data?: { [key: string]: any }): boolean => {
  if (!data) {
    return false;
  }

  const values = Object.values(data);

  const checkValue = (value: any) => (Array.isArray(value) ? value.some((item) => !!item) : value);

  return !!values.filter((value) => !!checkValue(value)).length;
};
