import { Actor } from "projects/common/types/models/message-lookup/actor";
import { Message } from "projects/common/types/models/message-lookup/message";
import { ScenarioItemType } from "projects/common/types/enums/campaign";
import { MessageState } from "projects/common/types/enums/report";

export const getMessageMock = (
  msisdn: string = "79991234567",
  { channel = ScenarioItemType.SMS, state = "Доставлено" }
): Message => ({
  msisdn,
  sentAt: "2020-10-04T15:04:24.045Z",
  deliveryAt: "2020-10-04T15:04:44.045Z",
  actor: getActorMock("1", {}),
  operator: "Мегафон",
  text: "Примерный текст для макета, чтобы провер. Примерный текст для макета, чтобы провер. Примерный текст для макета, чтобы провер. Примерный текст для макета, чтобы провер. Примерный текст для макета, чтобы провер. Link: https://seventech.atlassian.net/jira/software/c/projects/SEVENTECH/boards/50?modal=detail&selectedIssue=SEVENTECH-4379&assignee=5e3d2139c2f3370c861d6359 Примерный текст для макета, чтобы провер.",
  region: "Московская область",
  sender: "Sender",
  error: "Отключены PUSH",
  channel,
  trafficType: "TRANSACTIONAL",
  requestSource: "HTTP",
  state,
  client: {
    name: "клиент",
    id: "sfadfdasfdasf"
  },
  smsPartCount: 10,
  shortError: "Время истекло",
  countryCode: "US"
});

export const getActorMock = (id: string, { login = "Test" } = { login: "Test" }): Actor => ({
  id,
  login
});

export default {
  messages: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getMessageMock("79991234511", {}),
        getMessageMock("79991234522", { channel: ScenarioItemType.PUSH, state: MessageState.NOT_DELIVERED }),
        getMessageMock("79991234533", { channel: ScenarioItemType.PUSH }),
        getMessageMock("79991234544", { channel: ScenarioItemType.VIBER }),
        getMessageMock("79991234555", {}),
        getMessageMock("79991234566", { channel: ScenarioItemType.PUSH, state: MessageState.NOT_DELIVERED }),
        getMessageMock("79991234577", { channel: ScenarioItemType.WHATSAPP }),
        getMessageMock("79991234588", { channel: ScenarioItemType.PUSH, state: MessageState.NOT_DELIVERED }),
        getMessageMock("79991234599", {}),
        getMessageMock("79991234500", {})
      ]
    }
  },
  messagesCountLimit: {
    data: 10000
  },
  messagesDownload: new ArrayBuffer(1024)
};
