import React, { PropsWithChildren, useRef, useState } from "react";
import { ClassValue, clsx } from "clsx";
import Dropdown from "./Dropdown";
import { MenuProps } from "antd/es/menu";

interface OwnProps {
  menu?: MenuProps;
  className?: ClassValue;
  trigger?: "click" | "hover" | "contextMenu";
  onVisibleChange?: (visible: boolean) => void;
  inside?: boolean;
  hovered?: boolean;
  arrow?: boolean;
  placement?: "bottomLeft" | "bottomRight" | "topLeft" | "topRight";
}

type Props = OwnProps;

const DropdownButton = (props: PropsWithChildren<Props>) => {
  const {
    menu,
    children,
    className,
    trigger = "click",
    onVisibleChange,
    inside,
    hovered,
    arrow = true,
    placement = "bottomRight"
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [triggers, setTriggers] = useState([trigger]);

  return (
    <Dropdown
      destroyPopupOnHide
      arrow={arrow}
      placement={placement}
      menu={menu}
      trigger={triggers}
      className={clsx("overlay", className)}
      onOpenChange={(open) => {
        if (hovered) {
          open ? setTriggers([trigger, "hover"]) : setTriggers([trigger]);
        }
        setOpen(open);
        onVisibleChange?.(open);
      }}
      getPopupContainer={inside ? () => ref.current as HTMLDivElement : undefined}
      open={open}
      mouseLeaveDelay={0.1}
    >
      <div className="d-inline-block" ref={ref}>
        {children}
      </div>
    </Dropdown>
  );
};

export default DropdownButton;
