import React from "react";
import Fallback from "projects/common/shared/ui/fallback/Fallback";

const ManagementBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'common-management' */
      /* webpackPrefetch: 10 */
      "./Management"
    )
);

const ManagementModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <ManagementBundle />
  </React.Suspense>
);

export default ManagementModule;
