import packageInfo from "../../../../../package.json";
import { Env } from "projects/common/types/enums/env";
import { Project } from "projects/common/types/enums/project";
import { Environment } from "projects/common/types/interfaces/environment";
import { Language } from "projects/common/types/enums/language";

// Параметры запуска
const ENV: Env = (process.env.REACT_APP_ENV as Env) || Env.Prod;
const PROJECT: Project = (process.env.REACT_APP_PROJECT as Project) || Project.MEGAFON;
const LANGUAGE: Language = (process.env.REACT_APP_LANGUAGE as Language) || Language.Russian;

// Номер Яндекс.Метрика
const YANDEX_METRIC_DEFAULT = "87757639"; // https://metrika.yandex.ru/settings?id=87757639
const YANDEX_METRIC = {
  [Project.MEGAFON]: "88909313", // https://metrika.yandex.ru/settings?id=88909313
  [Project.SEVENTECH]: "88909344", // https://metrika.yandex.ru/settings?id=88909344
  [Project.SEVENTECH_EN]: "88909344", // https://metrika.yandex.ru/settings?id=88909344
  [Project.SEVENTECH_BY]: "88915517", // https://metrika.yandex.ru/settings?id=88915517
  [Project.GPBM]: "88909385", // https://metrika.yandex.ru/settings?id=88909385
  [Project.BEELINE]: null,
  [Project.WHITE_LABEL]: null
};

// Переопределения под стенды
const ENVIRONMENTS: { [key in Env]: Partial<Environment> } = {
  [Env.Local]: {
    SENTRY_DSN: null,
    YANDEX_METRIC_ENABLE: false
    // Быстрый переход на Qa
    // API_URL: process.env.PUBLIC_URL + "/qa/api",
  },
  [Env.Dev]: {
    YANDEX_METRIC_ENABLE: false
  },
  [Env.Qa]: {},
  [Env.Prod]: {
    YANDEX_METRIC_NUMBER: YANDEX_METRIC?.[PROJECT]
  }
};

const environment: Environment = {
  ENV,
  PROJECT,
  LANGUAGE,
  VERSION: packageInfo?.version,
  API_URL: process.env.PUBLIC_URL + "/api",
  BASENAME: process.env.PUBLIC_URL,
  YANDEX_METRIC_ENABLE: true,
  YANDEX_METRIC_NUMBER: YANDEX_METRIC_DEFAULT,
  SENTRY_DSN: "https://31a71f9f7b7f47bbaf172d417b223b46@sentry.infra.seven.tech/3",
  ...ENVIRONMENTS[ENV]
};

export default environment;
