import * as SentryReact from "@sentry/react";
import environment from "projects/common/shared/config/environment";
import { Credential } from "projects/common/types/models/user";
import { UserConfig } from "projects/common/types/interfaces/access";

const Sentry = {
  init: () => {
    if (!environment.SENTRY_DSN) return;

    SentryReact.init({
      dsn: environment.SENTRY_DSN,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [SentryReact.browserTracingIntegration(), SentryReact.replayIntegration()],
      tracesSampleRate: 1.0,
      release: environment.VERSION,
      environment: environment.ENV,
      initialScope: {
        tags: {
          projectName: environment.PROJECT,
          language: environment.LANGUAGE
        }
      }
    });
  },
  setUser: (credential: Credential, config?: UserConfig) => {
    if (!environment.SENTRY_DSN) return;

    const { user, roles = [] } = credential || {};
    const { id, name, ...userProps } = user || {};

    SentryReact.configureScope(function (scope) {
      scope.setTag("user-id", id);
      scope.setUser({
        id: id,
        username: name,
        roles,
        ...userProps,
        ...config
      });
    });
  },
  clearUser: () => {
    if (!environment.SENTRY_DSN) return;

    SentryReact.configureScope((scope) => {
      scope.setUser(null);
      scope.setTag("user-id", undefined);
    });
  },
  addBreadcrumb: (category: string) => {
    if (!environment.SENTRY_DSN) return;

    SentryReact.addBreadcrumb({
      category
    });
  },
  setTag: (key: string, val?: string | null) => {
    if (!environment.SENTRY_DSN) return;

    SentryReact.setTag(key, val);
  },
  setContext: (key: string, val: { [key: string]: any }) => {
    if (!environment.SENTRY_DSN) return;

    SentryReact.setContext(key, val);
  },
  clear: () => {
    if (!environment.SENTRY_DSN) return;

    SentryReact.configureScope((scope) => scope.clear());
  }
};

export default Sentry;
