import { Recipient, RecipientList } from "projects/common/types/models/list";
import { ListState, ListType } from "projects/common/types/enums/list";

export const getListMock = (
  id: string = "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  { ...data }: Partial<RecipientList> = {}
): RecipientList => ({
  id,
  state: ListState.CREATED,
  name: "Название",
  description:
    "Описание длинны текст Описание длинны текст Описание длинны текст Описание длинны текст Описание длинны текст Описание длинны текст",
  version: 0,
  isReportDone: false,
  errorCount: 1000,
  recipientCount: 1000,
  type: ListType.SIMPLE,
  createdAt: "2022-08-20T06:19:06.594Z",
  updatedAt: "2022-08-20T06:19:06.594Z",
  error: {
    code: "515",
    description: 'Не колонки "msisdn"'
  },
  ...data
});

export const getListRecipientMock = (
  id: string = "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  msisdn: string = "+79994729202"
): Recipient => ({
  id,
  msisdn
});

export default {
  lists: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getListMock("1"),
        getListMock("2", { state: ListState.DELETED }),
        getListMock("3"),
        getListMock("4", { state: ListState.CREATED_WITH_ERROR }),
        getListMock("5"),
        getListMock("6"),
        getListMock("7"),
        getListMock("8", { state: ListState.ERROR }),
        getListMock("9", { state: ListState.CREATING }),
        getListMock("10", { state: ListState.FAILED })
      ]
    }
  },
  list: { data: getListMock("1", { state: ListState.CREATED, isReportDone: false }) },
  listRecipients: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getListRecipientMock("1"),
        getListRecipientMock("2", "+76664729202"),
        getListRecipientMock("3"),
        getListRecipientMock("4", "+78884729202"),
        getListRecipientMock("5"),
        getListRecipientMock("6"),
        getListRecipientMock("7"),
        getListRecipientMock("8"),
        getListRecipientMock("9", "+77774729202"),
        getListRecipientMock("10")
      ]
    }
  },
  listRecipientUrl: {
    data: {
      url: "https://int.seven.tech/dev-mgfninformer/api/reports/public/informer/v1/download/%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csvfc3d3c70-ee07-4091-b14e-244106ad3ef2?response-content-disposition=attachment%3B%20filename%20%3D%22%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csv%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211210T060143Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=W2JozDrB3cuSc5AJGTXp%2F20211210%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Signature=f935e2984d3bc5f56d821e209590fcbbe0963babb1dec226bf3dfaf86f647226"
    }
  },
  listErrorUrl: {
    data: {
      url: "https://int.seven.tech/dev-mgfninformer/api/reports/public/informer/v1/download/%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csvfc3d3c70-ee07-4091-b14e-244106ad3ef2?response-content-disposition=attachment%3B%20filename%20%3D%22%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%2009.12.2021%2011%3A48%3A23.csv%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211210T060143Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=W2JozDrB3cuSc5AJGTXp%2F20211210%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Signature=f935e2984d3bc5f56d821e209590fcbbe0963babb1dec226bf3dfaf86f647226"
    }
  }
};
