import { ScenarioItemType } from "projects/common/types/enums/campaign";

export default {
  channels: {
    data: {
      channels: [
        ScenarioItemType.SMS,
        ScenarioItemType.VIBER,
        ScenarioItemType.VK,
        ScenarioItemType.WHATSAPP,
        ScenarioItemType.PUSH
      ]
    }
  },
  senders: { data: { senders: ["SevenTech", "Megafon", "Megafon2"] } }
};
