/**
 * Sender-pattern public API
 * API для работы с именами и шаблонами
 *
 * The version of the OpenAPI document: 2.1
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Состояние шаблона
 */

export enum PatternStateEnum {
  APPROVAL = "APPROVAL",
  APPROVED = "APPROVED",
  DELETED = "DELETED",
  REJECTED = "REJECTED",
  ACTIVE = "ACTIVE"
}
