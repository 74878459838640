import error from "./error";
import campaigns from "./campaigns";
import lists from "./lists";
import auth from "./auth";
import user from "./user";
import crm from "./crm";
import reports from "./reports";
import audit from "./audit";
import messageLookup from "./messageLookup";
import exports from "./export";
import senderPattern from "./senderPattern";
import preset from "./preset";
import file from "./file";
import priceList from "./priceList";
import simpleMessage from "./simpleMessage";
import userAccess from "./userAccess";
import client from "./client";

export default {
  ...error,
  ...user,
  ...campaigns,
  ...reports,
  ...auth,
  ...crm,
  ...audit,
  ...messageLookup,
  ...exports,
  ...senderPattern,
  ...preset,
  ...lists,
  ...file,
  ...priceList,
  ...simpleMessage,
  ...userAccess,
  ...client
};
