import { api } from "./api";
import { ChannelsResponseWrapper } from "projects/common/types/models/crm/channelsResponseWrapper";
import { SendersResponse } from "projects/common/types/models/crm";
import { ScenarioItemType } from "projects/common/types/enums/campaign";
import { SendersResponseWrapper } from "projects/common/types/models/crm/sendersResponseWrapper";

const prefix = `/crm/user-config/v1`;
export const crmApiUrl = {
  channels: `${prefix}/channels`,
  senders: (id: string) => `${prefix}/channels/${id}/senders`
};

export const crmApi = api.injectEndpoints({
  endpoints: (build) => ({
    crmChannels: build.query<ScenarioItemType[] | undefined, void>({
      query: () => ({
        url: crmApiUrl.channels
      }),
      transformResponse: (response: ChannelsResponseWrapper, meta, arg) => response.data?.channels,
      providesTags: (_result, _err, id) => [{ type: "CrmChannels" }]
    }),
    crmSenders: build.query<SendersResponse, ScenarioItemType>({
      query: (channel) => ({
        url: crmApiUrl.senders(channel)
      }),
      transformResponse: (response: SendersResponseWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "CrmChannels" }]
    })
  })
});

export const { useLazyCrmChannelsQuery, useLazyCrmSendersQuery, useCrmChannelsQuery } = crmApi;
