/**
 * Campaign API
 * API для рассылок
 *
 * The version of the OpenAPI document: 1.12
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Тип источника для рассылки
 */
export enum SourceType {
  LIST = "LIST",
  FILE = "FILE",
  REQUEST = "REQUEST"
}
