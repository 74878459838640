import React, { PropsWithChildren } from "react";
import styles from "./ContentWrapper.module.scss";
import { ClassValue, clsx } from "clsx";

interface OwnProps {
  className?: ClassValue;
}

type Props = OwnProps;

const ContentWrapper = (props: PropsWithChildren<Props>) => {
  const { className } = props;

  return <div className={clsx(styles.content, className)}>{props.children}</div>;
};

export default ContentWrapper;
