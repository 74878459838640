import React, { PropsWithChildren } from "react";
import { useTranslation } from "./useTranslation";
import { Translation } from "./translation";
import { Language } from "projects/common/types/enums/language";
import environment from "projects/common/shared/config/environment";

interface OwnProps {
  i18nKey?: keyof Translation;
}

type Props = OwnProps;

const Trans = (props: PropsWithChildren<Props>) => {
  const { children, i18nKey } = props;
  const t = useTranslation();

  if (!i18nKey && !children) {
    return null;
  }

  if (environment.LANGUAGE === Language.Russian) {
    return <>{children}</>;
  }

  const child = t(children || "", i18nKey);

  if (i18nKey && typeof child === "string") {
    return <span dangerouslySetInnerHTML={{ __html: child }} />;
  }

  return <>{child}</>;
};

export default Trans;
