/**
 * Message preset API
 * API для управления пресетами сообщений
 *
 * OpenAPI spec version: 1.0
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PageRequestFilter {
  value?: string;
  rule?: PageRequestFilter.RuleEnum;
}
export namespace PageRequestFilter {
  export type RuleEnum = "STRICT" | "START_WITH" | "END_WITH" | "CONTAINS";
  export const RuleEnum = {
    STRICT: "STRICT" as RuleEnum,
    STARTWITH: "START_WITH" as RuleEnum,
    ENDWITH: "END_WITH" as RuleEnum,
    CONTAINS: "CONTAINS" as RuleEnum
  };
}
