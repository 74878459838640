import { api } from "./api";
import { PageRequest } from "../../types/interfaces/pageResponse";
import {
  CreateCredentialRequest,
  CreateDepartmentRequest,
  CreateUserRequest,
  CredentialPage,
  CredentialWrapper,
  Department,
  Credential,
  DepartmentPage,
  DepartmentWrapper,
  PageCredentialWrapper,
  PageDepartmentWrapper,
  PageUserWrapper,
  Password,
  PasswordWrapper,
  UpdateCredentialRequest,
  UpdateDepartmentRequest,
  UpdateUserRequest,
  User,
  UserPage,
  UserWrapper
} from "../../types/models/client";

const prefix = `/user-model/client-area/v1`;
export const clientApiUrl = {
  credentials: `${prefix}/credential/all`,
  credentialResetPassword: (id: number | string) => `${prefix}/credential/${id}/reset-password`,
  credentialCreate: `${prefix}/credential`,
  credentialGet: (id: number | string) => `${prefix}/credential/${id}`,
  credentialUpdate: (id: number | string) => `${prefix}/credential/${id}`,
  departments: `${prefix}/department/all`,
  departmentCreate: `${prefix}/department`,
  departmentGet: (id: number | string) => `${prefix}/department/${id}`,
  departmentUpdate: (id: number | string) => `${prefix}/department/${id}`,
  users: `${prefix}/user/all`,
  userCreate: `${prefix}/user`,
  userGet: (id: number | string) => `${prefix}/user/${id}`,
  userUpdate: (id: number | string) => `${prefix}/user/${id}`
};

export const clientApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCredentials: build.query<CredentialPage, PageRequest | void>({
      query: ({ page = 0, size = 10, ...other }: PageRequest = { page: 0, size: 10 }) => ({
        url: clientApiUrl.credentials,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: PageCredentialWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [
        ...(_result?.content?.map(
          ({ id }) =>
            ({
              type: "ClientCredential",
              id
            } as const)
        ) || []),
        { type: "ClientCredential" as const, id: "LIST" }
      ]
    }),
    resetPasswordCredential: build.mutation<Password, { id: string; isCreating?: boolean }>({
      query: ({ id, isCreating = false }) => ({
        url: clientApiUrl.credentialResetPassword(id),
        params: { isCreating },
        method: "POST"
      }),
      transformResponse: (response: PasswordWrapper, meta, arg) => response.data
    }),
    createCredential: build.mutation<Credential, CreateCredentialRequest>({
      query: (body) => ({
        url: clientApiUrl.credentialCreate,
        method: "POST",
        body
      }),
      transformResponse: (response: CredentialWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, id) => [{ type: "ClientCredential", id: "LIST" }]
    }),
    getCredential: build.query<Credential, string>({
      query: (id: string) => clientApiUrl.credentialGet(id),
      transformResponse: (response: CredentialWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "ClientCredential", id }]
    }),
    updateCredential: build.mutation<Credential, { id: string; data: UpdateCredentialRequest }>({
      query: ({ id, data: body }) => ({
        url: clientApiUrl.credentialUpdate(id),
        method: "PUT",
        body
      }),
      transformResponse: (response: CredentialWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, { id }) => {
        if (_err) return [];
        return [{ type: "ClientCredential", id }];
      }
    }),
    getAllDepartments: build.query<DepartmentPage, PageRequest | void>({
      query: ({ page = 0, size = 10, ...other }: PageRequest = { page: 0, size: 10 }) => ({
        url: clientApiUrl.departments,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: PageDepartmentWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [
        ...(_result?.content?.map(({ id }) => ({ type: "ClientDepartment", id } as const)) || []),
        { type: "ClientDepartment" as const, id: "LIST" }
      ]
    }),
    createDepartment: build.mutation<Department, CreateDepartmentRequest>({
      query: (body) => ({
        url: clientApiUrl.departmentCreate,
        method: "POST",
        body
      }),
      transformResponse: (response: DepartmentWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, id) => [{ type: "ClientDepartment", id: "LIST" }]
    }),
    getDepartment: build.query<Department, string>({
      query: (id: string) => clientApiUrl.departmentGet(id),
      transformResponse: (response: DepartmentWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "ClientDepartment", id }]
    }),
    updateDepartment: build.mutation<Department, { id: string; data: UpdateDepartmentRequest }>({
      query: ({ id, data: body }) => ({
        url: clientApiUrl.departmentUpdate(id),
        method: "PUT",
        body
      }),
      transformResponse: (response: DepartmentWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, { id }) => {
        if (_err) return [];
        return [{ type: "ClientDepartment", id }];
      }
    }),
    getAllUsers: build.query<UserPage, PageRequest | void>({
      query: ({ page = 0, size = 10, ...other }: PageRequest = { page: 0, size: 10 }) => ({
        url: clientApiUrl.users,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: PageUserWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [
        ...(_result?.content?.map(({ id }) => ({ type: "ClientUser", id } as const)) || []),
        { type: "ClientUser" as const, id: "LIST" }
      ]
    }),
    createUser: build.mutation<User, CreateUserRequest>({
      query: (body) => ({
        url: clientApiUrl.userCreate,
        method: "POST",
        body
      }),
      transformResponse: (response: UserWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, id) => [{ type: "ClientUser", id: "LIST" }]
    }),
    getUser: build.query<User, string>({
      query: (id: string) => clientApiUrl.userGet(id),
      transformResponse: (response: UserWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "ClientUser", id }]
    }),
    updateUser: build.mutation<User, { id: string; data: UpdateUserRequest }>({
      query: ({ id, data: body }) => ({
        url: clientApiUrl.userUpdate(id),
        method: "PUT",
        body
      }),
      transformResponse: (response: UserWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, { id }) => {
        if (_err) return [];
        return [{ type: "ClientUser", id }];
      }
    })
  })
});

export const {
  useGetAllCredentialsQuery,
  useLazyGetAllCredentialsQuery,
  useResetPasswordCredentialMutation,
  useCreateCredentialMutation,
  useGetCredentialQuery,
  useUpdateCredentialMutation,
  useGetAllDepartmentsQuery,
  useLazyGetAllDepartmentsQuery,
  useCreateDepartmentMutation,
  useGetDepartmentQuery,
  useUpdateDepartmentMutation,
  useGetAllUsersQuery,
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation
} = clientApi;
