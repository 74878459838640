import { api } from "./api";
import { AccessProfile, AccessProfileListWrapper } from "../../types/models/user-access";

const prefix = `/user-access/client-area/v1`;
export const userAccessApiUrl = {
  accessProfiles: `${prefix}/access-profile/all`
};

const userAccessApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAccessProfile: build.query<Array<AccessProfile>, void>({
      query: () => ({
        url: userAccessApiUrl.accessProfiles
      }),
      transformResponse: (response: AccessProfileListWrapper, meta, arg) => response.data,
      providesTags: [{ type: "AccessProfile", id: "LIST" }]
    })
  }),
  overrideExisting: false
});

export const { useGetAllAccessProfileQuery } = userAccessApi;
