/**
 * Sender-pattern public API
 * API для работы с именами и шаблонами
 *
 * The version of the OpenAPI document: 2.1
 * Contact: support@seven.tech
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Состояние имени
 */
export enum SenderState {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED"
}
