import React, { PropsWithChildren } from "react";
import "./Navbar.scss";
import { Typography } from "projects/common/types/interfaces/typography";
import { ClassValue, clsx } from "clsx";
import { getTypographyClassNames } from "projects/common/shared/lib/classNames";

interface OwnProps extends Partial<Typography> {
  className?: ClassValue;
  right?: boolean;
  border?: boolean;
  id?: string;
}

type Props = OwnProps;

const Navbar = (props: PropsWithChildren<Props>) => {
  const { className, id, children, right, border } = props;

  return (
    <div
      id={id}
      className={clsx("navbar", className, getTypographyClassNames(props), {
        navbar_right: right,
        navbar_border: border
      })}
    >
      {children}
    </div>
  );
};

export default Navbar;
