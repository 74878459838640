export const INPUT_ERRORS = {
  invalid: "Ошибка",
  required: "Обязательное поле",
  typeError: "Некорректное значение",
  numberMin: "Минимальное число {{min}}",
  numberMax: "Максимальное число {{max}}",
  positive: "Число должно быть больше 0",
  stringMin: "Минимальная длина {{min}} символов",
  stringMax: "Максимальная длина {{max}} символов",
  stringLength: "Необходимая длина {{length}} символов",
  email: "Некорректный формат Email",
  matches: "Не соответствует формату",
  arrayMin: "Выберите минимум {{min}} значения",
  arrayMax: "Выбрать можно максимум {{max}}"
} as const;
