import React, { PropsWithChildren } from "react";
import MenuAnt, { MenuProps } from "antd/es/menu";
import { MenuItemType, SubMenuType } from "antd/es/menu/hooks/useItems";
import styles from "./Menu.module.scss";
import { clsx } from "clsx";

export type MenuItem = MenuItemType | SubMenuType;

export function getMenuItem(data: Partial<MenuItem> = {}): MenuItem {
  let { ...propsItem } = data;

  return {
    ...propsItem
  } as MenuItem;
}

interface OwnProps extends MenuProps {}

type Props = OwnProps;

const Menu = (props: PropsWithChildren<Props>) => {
  const { ...propsMenu } = props;

  return (
    <div className={styles.wrapper}>
      <MenuAnt {...propsMenu} className={clsx(styles.menu, propsMenu?.className)} />
    </div>
  );
};

export default Menu;
