export default {
  auth: {
    "access_token":
      "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJhUHZSRGR1WmhGdEpfbEVKNV84U2tJYTJnMmIyT0FhcU8yZzROUHNYNUpVIn0.eyJleHAiOjE2ODUzNDAyMjUsImlhdCI6MTY4NTMzOTkyNSwianRpIjoiYjMzMjI0YjktMDNkYi00ZTg0LTg3ZTQtMDIyMmJiZGIyNzRjIiwiaXNzIjoiaHR0cHM6Ly9tZ2ZuLWh1Yi5kZXYuc2V2ZW4udGVjaC9hdXRoL3JlYWxtcy9jcnAiLCJhdWQiOiJkZWZhdWx0Iiwic3ViIjoiZjo5MDMxYzA4NC0xMjNkLTQ3MGEtODk1ZS0wYmE4Njg3ZjBhOGE6dGVuYW50X2FkbWluIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoidWkiLCJzZXNzaW9uX3N0YXRlIjoiMWZhMjI5OWYtY2RjMy00OTE5LWJjY2MtNjRmZjE5ZjE5ZTU1IiwicmVzb3VyY2VfYWNjZXNzIjp7ImRlZmF1bHQiOnsicm9sZXMiOlsidGVuYW50X2FkbWluIl19LCJ1aSI6eyJyb2xlcyI6WyJ0ZW5hbnRfYWRtaW4iXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJzaWQiOiIxZmEyMjk5Zi1jZGMzLTQ5MTktYmNjYy02NGZmMTlmMTllNTUiLCJjbGllbnRJZCI6ImZkZDYxNjg3LWZkMjgtNDMyZi04YzE2LTUyOTQ0YTdhZjFiYSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZGVwYXJ0bWVudElkIjoiZDcwMzQ3ZGYtNTdmYi00NWFhLWFlMzEtNGZmZGM2OWRkZDdlIiwiaXBBZGRyZXNzIjoiMTAuMjE2LjQuMjUzIiwidGVuYW50SWQiOiJmZGQ2MTY4Ny1mZDI4LTQzMmYtOGMxNi01Mjk0NGE3YWYxYmEiLCJjcmVkZW50aWFsSWQiOiJjOGEzZjQwNy1mOWZiLTRkMDMtODg3MS0zNTI3MmI2YTcwM2UiLCJ0aW1lWm9uZSI6MywicHJlZmVycmVkX3VzZXJuYW1lIjoidGVuYW50X2FkbWluIiwibG9naW4iOiJ0ZW5hbnRfYWRtaW4iLCJ1c2VySWQiOiI2ODNhZWEwNi1hOGE1LTQ0MTItODdlNy0zZTVlZjExMmU2ZTgifQ.ccqaCIgf6Pwm_cXxwj6nHWhUeeXJ_CAuN4Y8nGDhmB1P_C34dQ3G0pFbGVNKf-AN_zoR6nqdWvFW2DTAXtB7JQX2-8XbuAd69DIoUbldAM1IsVkLoVGwtxM43qaoW2opNhFMfoKEgnQg63LP4abaK-Lz8S7riUnrND21XvmDcN2PjXMi9yuczEsDtjTxvGsvSUqEHY-QMUd1Ev3huLXjf_NnOvgbf4t7nKFFp5K_4nQw2klA7RWFJW-V5mr7ft4VPR2hONvHAXofdwSxF_Nup-J8M0SuE2TW3H7fwxTeUW0z4-PJjRKwYiHrEuqH8vw5u4TkLAmKOX5P6G7hdfY50w",
    "expires_in": 300,
    "refresh_expires_in": 1800,
    "refresh_token":
      "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI4NzdmOTJlYi05NDFlLTQ1ZjUtYWFkOS1jNTBlNjliZGUyZmYifQ.eyJleHAiOjE2ODUzNDE3MjUsImlhdCI6MTY4NTMzOTkyNSwianRpIjoiN2ZhZDkzMmYtZGQxYS00YWMwLWFmODItZjMxYzgxZjdjYTAwIiwiaXNzIjoiaHR0cHM6Ly9tZ2ZuLWh1Yi5kZXYuc2V2ZW4udGVjaC9hdXRoL3JlYWxtcy9jcnAiLCJhdWQiOiJodHRwczovL21nZm4taHViLmRldi5zZXZlbi50ZWNoL2F1dGgvcmVhbG1zL2NycCIsInN1YiI6ImY6OTAzMWMwODQtMTIzZC00NzBhLTg5NWUtMGJhODY4N2YwYThhOnRlbmFudF9hZG1pbiIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJ1aSIsInNlc3Npb25fc3RhdGUiOiIxZmEyMjk5Zi1jZGMzLTQ5MTktYmNjYy02NGZmMTlmMTllNTUiLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJzaWQiOiIxZmEyMjk5Zi1jZGMzLTQ5MTktYmNjYy02NGZmMTlmMTllNTUifQ.dqdmTa-zNBNcMTmMi9t3zS9LgjsEwF3mU8Y0TuEguEA",
    "token_type": "Bearer",
    "not-beforepolicy": 1675847516,
    "session_state": "1fa2299f-cdc3-4919-bccc-64ff19f19e55",
    "scope": "email profile"
  },
  authError: {
    error: "invalid_ip",
    error_description: "Invalid IP Address error"
  }
};
