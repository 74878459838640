import { Access, UserConfig } from "projects/common/types/interfaces/access";
import { saveStorage } from "projects/common/shared/lib/localStorage";
import { RoleName } from "projects/common/types/enums/roleName";
import { StorageName } from "projects/common/types/enums/storageName";
import { Credential } from "projects/common/types/models/user";
import environment from "../../shared/config/environment";
import { Project } from "../../types/enums/project";

export const getUserAccess = (roles?: RoleName[], config?: UserConfig, credential?: Credential): Access => {
  let access: Access = {
    global: false,
    organisation: false,
    department: false,
    campaignVisible: false,
    campaignCreate: false,
    reportVisible: false,
    reportCreate: false,
    auditVisible: false,
    messageLookupVisible: false,
    namesVisible: false,
    presetVisible: false,
    presetCreate: false,
    listVisible: false,
    listCreate: false,
    simpleMessageVisible: false,
    managementVisible: false,
    userVisible: false,
    departmentVisible: false,
    userEdit: false,
    departmentEdit: false,
    smsPatternsVisible: false,
    smsPatternCheckVisible: false
  };

  roles?.forEach((role) => {
    const roleAccess = getRoleAccess(role);
    access = { ...access, ...roleAccess };
  });

  // TODO: Удалить в https://seventech.atlassian.net/browse/SEVENTECH-18141
  if (
    credential?.user?.client?.id === "3765469f-cd95-4f12-84ae-f5d9f819c788" &&
    environment.PROJECT === Project.SEVENTECH_BY
  ) {
    access = { ...access, messageLookupVisible: false };
  }

  saveStorage<Access>(StorageName.Access, access);

  return access;
};

export const getRoleAccess = (role: RoleName): Partial<Access> => {
  switch (role) {
    case RoleName.GlobalAdmin:
      return {
        global: true,
        organisation: true,
        department: true,
        campaignVisible: true,
        reportVisible: true,
        reportCreate: true,
        auditVisible: true,
        presetVisible: true,
        listVisible: true,
        messageLookupVisible: true
      };
    case RoleName.TenantAdmin:
      return {
        organisation: true,
        department: true,
        campaignVisible: true,
        campaignCreate: true,
        reportVisible: true,
        reportCreate: true,
        auditVisible: true,
        presetVisible: true,
        presetCreate: true,
        listVisible: true,
        listCreate: true,
        messageLookupVisible: true
      };
    case RoleName.DepartmentAdmin:
      return {
        department: true,
        campaignVisible: true,
        campaignCreate: true,
        reportVisible: true,
        reportCreate: true,
        auditVisible: true,
        presetVisible: true,
        presetCreate: true,
        listVisible: true,
        listCreate: true,
        messageLookupVisible: true
      };
    case RoleName.TenantStatist:
      return {
        organisation: true,
        department: true,
        campaignVisible: true,
        reportVisible: true,
        reportCreate: true,
        listVisible: true,
        messageLookupVisible: true
      };
    case RoleName.DepartmentStatist:
      return {
        department: true,
        campaignVisible: true,
        reportVisible: true,
        reportCreate: true,
        listVisible: true,
        messageLookupVisible: true
      };
    case RoleName.User:
      return {
        campaignVisible: true,
        campaignCreate: true,
        reportVisible: true,
        reportCreate: true,
        presetVisible: true,
        presetCreate: true,
        listVisible: true,
        listCreate: true,
        messageLookupVisible: true
      };
    case RoleName.MessageLookup:
      return {
        messageLookupVisible: true
      };
    case RoleName.SimpleMessageSending:
      return {
        simpleMessageVisible: true
      };
    case RoleName.DeprecatedSenderPatternViewer:
      return {
        namesVisible: true,
        smsPatternsVisible: true
      };
    case RoleName.SenderPatternViewer:
      return {
        namesVisible: true
      };
    case RoleName.SmsPatternViewer:
      return {
        smsPatternsVisible: true,
        smsPatternCheckVisible: true
      };
    case RoleName.SmsPatternValidator:
      return {
        smsPatternCheckVisible: true
      };
    case RoleName.ClientEditor:
      return {
        managementVisible: true,
        userVisible: true,
        departmentVisible: true,
        userEdit: true,
        departmentEdit: true
      };
    case RoleName.ClientViewer:
      return {
        managementVisible: true,
        userVisible: true,
        departmentVisible: true
      };
    case RoleName.DepartmentEditor:
      return {
        managementVisible: true,
        userVisible: true,
        userEdit: true
      };
    case RoleName.DepartmentViewer:
      return {
        managementVisible: true,
        userVisible: true
      };
    case RoleName.SendSmpp:
    case RoleName.SendMessage:
    default:
      return {};
  }
};
